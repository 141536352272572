import { makeStyles, Theme, useTheme } from "@material-ui/core";
import { hgemColours } from "../../../themes/defaultTheme";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  scrollContainer: {
    overflow: "scroll",
    backgroundColor: hgemColours.OffWhite,
  },
  container: {
    overflow: "auto",
    backgroundColor: hgemColours.OffWhite,
  },
  frame: {
    border: "none",
    overflowY: "scroll",
    overflowX: "clip",
    paddingBottom: "50px",
    height: "75vh",
    backgroundColor: hgemColours.White,
    [theme.breakpoints.up("lg")]: {
      height: "77vh",
    },
  },
  moreActionsContainer: {
    display: "inline-flex",
    justifyContent: "end",
    width: "100%",
    backgroundColor: hgemColours.OffWhite,
    marginBottom: "1%",
  },
  moreActionsMenu: {
    transformOrigin: "top right",
  },
  icon: {
    fontSize: "medium",
  },
  iconLeft: {
    marginRight: "15px",
  },
  iconRight: {
    marginLeft: "15px",
  },
}));

export const PandoraReportStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
