import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import i18n from "../../../localizations/i18n";

type Props = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  quarantineVisit: () => Promise<void>;
};

const QuarantineConfirmationDialog = (props: Props) => {
  const { isOpen, setIsOpen, quarantineVisit } = props;

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {i18n.translate("MORE_ACTIONS_MENU_Quarantine_Dialog_Title")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          style={{ color: "inherit" }}
        >
          {i18n.translate("MORE_ACTIONS_MENU_Quarantine_Dialog_Message_1")}
          <br />
          {i18n.translate("MORE_ACTIONS_MENU_Quarantine_Dialog_Message_2")}
          <br />
          {i18n.translate("MORE_ACTIONS_MENU_Quarantine_Dialog_Message_3")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setIsOpen(false);
            quarantineVisit();
          }}
          autoFocus
        >
          {i18n.translate("MORE_ACTIONS_MENU_Quarantine_Confirm")}
        </Button>
        <Button onClick={() => setIsOpen(false)}>
          {i18n.translate("COMMON_CANCEL")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QuarantineConfirmationDialog;
