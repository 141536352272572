import { Button, Icon, Menu, MenuItem, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { MoreActionsMenuStyles } from "./styles/moreActionsMenu";
import { i18n } from "../../../localizations";

type MenuItem = {
  icon?: string;
  title: string;
  onClick: () => void;
};

type Props = {
  menuItems: MenuItem[];
};

const MoreActionsMenu = ({ menuItems }: Props) => {
  const classes = MoreActionsMenuStyles();
  const [anchor, setAnchor] = useState<undefined | HTMLElement>();
  const isMoreActionsOpen = Boolean(anchor);

  const handleClose = () => {
    setAnchor(undefined);
  };
  return (
    <div className={classes.moreActionsContainer}>
      <Button
        id="demo-customized-button"
        aria-controls={isMoreActionsOpen ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={isMoreActionsOpen ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={(e) => setAnchor(e.currentTarget)}
      >
        {i18n.translate("MORE_ACTIONS_MENU_Actions")}
        <Icon
          className={`${classes.icon} ${
            classes.iconRight
          } fa-regular fa-chevron-${isMoreActionsOpen ? "up" : "down"}`}
        />
      </Button>

      <Menu
        onClose={handleClose}
        anchorEl={anchor}
        open={isMoreActionsOpen}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {menuItems.map((menuItem) => (
          <MenuItem
            key={menuItem.title}
            onClick={() => {
              handleClose();
              menuItem.onClick();
            }}
          >
            <Icon
              className={`${classes.icon} ${classes.iconLeft} fa-solid ${menuItem.icon}`}
            />
            <Typography>{menuItem.title}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default MoreActionsMenu;
