import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import IsAuthorised from "../../../authorisation/permissions";
import { PermissionType } from "../../../authorisation/permissionsUtils";
import i18n from "../../../localizations/i18n";
import { fetchVisitReportReviewContent } from "../../../state/actions/VisitReport-Actions";
import { IReviewContent } from "../../../state/types/VisitReport";
import { VisitReportContent } from "../state/selectors";
import { ReviewReportStyles } from "../styles/reviewReportStyles";
import ReviewResponse from "./reviewResponse";
import ReviewResponseLink from "./reviewResponselink";
import InboxListIcon, {
  iconMap,
} from "../../inboxList/components/inboxListIcons";
import { customIconMap } from "../../inboxList/utils";
import MoreActionsMenu from "../../_common/moreActionsMenu/moreActionsMenu";
import QuarantineConfirmationDialog from "../../_common/moreActionsMenu/quarantineConfirmationDialog";
import { postQuarantineVisitApi } from "../../../state/api/VisitDetails-API";
import { AuthServiceInstance } from "../../../authentication/authService";
import QuarantineErrorDialog from "../../_common/moreActionsMenu/quarantineErrorDialog";

interface IProps {
  visitId: number;
  showResponseOption: boolean;
  onQuarantine?: () => void;
}

const ReviewReport = (props: IProps) => {
  const classes = ReviewReportStyles();
  const dispatch = useDispatch();
  const reportContent = useSelector(VisitReportContent);
  const [replyButtonEnabled, setReplyButtonEnabled] = useState(false);
  const [userCanQuarantine, setUserCanQuarantine] = useState(false);
  const [quarantineDialogOpen, setQuarantineDialogOpen] = useState(false);
  const [quarantineErrorDialogOpen, setQuarantineErrorDialogOpen] =
    useState(false);

  useEffect(() => {
    const checkUserCanReply = async () => {
      const canReplyToReviews = await IsAuthorised(PermissionType.SendReplies);

      if (canReplyToReviews) {
        setReplyButtonEnabled(canReplyToReviews);
      }
    };
    checkUserCanReply();

    const checkUserCanQuarantine = async () => {
      const canQuarantine = await IsAuthorised(PermissionType.QuarantineVisits);

      if (canQuarantine) {
        setUserCanQuarantine(canQuarantine);
      }
    };
    checkUserCanQuarantine();
  });
  useEffect(() => {
    if (props.visitId && props.visitId !== reportContent?.visitId) {
      dispatch(fetchVisitReportReviewContent(props.visitId));
    }
  }, [dispatch, props.visitId, reportContent]);

  const renderStars = (score: number | undefined) => {
    const fullStars = score ? score : 0;
    const stars = [1, 2, 3, 4, 5];

    return (
      <div className={classes.stars}>
        {stars.map((star, idx) => {
          if (fullStars >= star) {
            return <i key={idx} className="fas fa-star"></i>;
          } else {
            return <i key={idx} className="far fa-star"></i>;
          }
        })}
      </div>
    );
  };

  const renderCommentResponse = (
    responseDate?: Date,
    responseText?: string
  ) => {
    return (
      <div className={classes.responseWrapper}>
        <i className={`fas fa-reply ${classes.responseIcon}`}></i>
        <div className={classes.responseContainer}>
          <span className={classes.respponseHeader}>
            {i18n.translate("VISIT_DETAILS_REVIEW_HEADER_Response_Title")}
            {responseDate &&
              dayjs(responseDate).format(
                i18n.translate(
                  "VISIT_DETAILS_REVIEW_HEADER_Response_Date_Format"
                )
              )}
          </span>
          {responseText}
        </div>
      </div>
    );
  };

  const getReviewAuthorIcon = (content: IReviewContent) => {
    let icon = "fal fa-smile";
    switch (content.score) {
      case 3:
        icon = "fal fa-meh";
        break;
      case 2:
      case 1:
        icon = "fal fa-frown";
        break;
    }

    return icon;
  };

  const quarantineVisit = async () => {
    const clientId = await AuthServiceInstance.getClientId();
    try {
      await postQuarantineVisitApi(clientId, props.visitId);
      props.onQuarantine?.();
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const moreActionsMenuItems = [
    {
      title: i18n.translate("MORE_ACTIONS_MENU_Quarantine"),
      icon: "fa-trash",
      onClick: () => setQuarantineDialogOpen(true),
    },
  ];

  return (
    <>
      {reportContent && reportContent.reviewContent && (
        <>
          {userCanQuarantine && (
            <>
              <MoreActionsMenu
                menuItems={moreActionsMenuItems}
              ></MoreActionsMenu>
              <QuarantineConfirmationDialog
                isOpen={quarantineDialogOpen}
                setIsOpen={setQuarantineDialogOpen}
                quarantineVisit={quarantineVisit}
              />
              <QuarantineErrorDialog
                isOpen={quarantineErrorDialogOpen}
                setIsOpen={setQuarantineErrorDialogOpen}
              />
            </>
          )}
          <div className={classes.headerContainer}>
            <span className={classes.headerLocation}>
              {reportContent.reviewContent.location}
            </span>
            {reportContent.reviewContent.date && (
              <span className={classes.headerDate}>
                {dayjs(reportContent.reviewContent.date).format(
                  i18n.translate("VISIT_DETAILS_REVIEW_HEADER_Date_Format")
                )}
              </span>
            )}
          </div>

          <div className={classes.scoreContainer}>
            <span className={classes.scoreContainerRow}>
              {reportContent?.reviewContent?.reviewSource ? (
                <InboxListIcon
                  icon={
                    reportContent?.reviewContent?.reviewSource as
                      | keyof typeof iconMap
                      | keyof typeof customIconMap
                  }
                  className={classes.reportIcon}
                />
              ) : null}
              {renderStars(reportContent?.reviewContent?.score)}

              <div className={classes.score}>
                {reportContent?.reviewContent?.score}
                {i18n.translate("VISIT_DETAILS_REVIEW_HEADER_ScoreSuffix")}
              </div>
              {props.showResponseOption && replyButtonEnabled && (
                <div
                  style={{
                    marginTop: "1%",
                    marginBottom: "1%",
                    marginLeft: "2%",
                  }}
                >
                  <ReviewResponseLink
                    visitId={reportContent.visitId}
                    sourceName={reportContent.reviewContent.reviewSource}
                  />
                </div>
              )}
            </span>
            {reportContent?.reviewContent?.author && (
              <div className={classes.author}>
                <span className={classes.authorIcon}>
                  <i
                    className={getReviewAuthorIcon(
                      reportContent?.reviewContent
                    )}
                  ></i>
                </span>
                {reportContent?.reviewContent?.author}
              </div>
            )}

            <div className={classes.divider}></div>

            {reportContent?.reviewContent?.reviewSubject && (
              <div className={classes.subject}>
                {reportContent?.reviewContent?.reviewSubject}
              </div>
            )}

            {reportContent?.reviewContent?.reviewText && (
              <div className={classes.comments}>
                {reportContent?.reviewContent?.reviewText}
              </div>
            )}
            {!reportContent?.reviewContent?.reviewText && (
              <div className={classes.emptyComments}>
                {i18n.translate(
                  "VISIT_DETAILS_REVIEW_HEADER_EmptyComment_Placeholder"
                )}
              </div>
            )}
          </div>

          {reportContent.reviewContent.response.length > 0 && (
            <>
              {reportContent.reviewContent.response.map((r, index) => {
                return (
                  <div key={index}>
                    {renderCommentResponse(r.responseDate, r.responseText)}
                  </div>
                );
              })}
            </>
          )}

          {reportContent.reviewContent.response.length === 0 &&
            reportContent.reviewContent.pendingResponse &&
            reportContent.reviewContent.pendingResponse.length > 0 && (
              <>
                {reportContent.reviewContent.pendingResponse.map((p, index) => {
                  return (
                    <div key={index} style={{ marginBottom: "1%" }}>
                      {renderCommentResponse(p.createdDate, p.responseText)}
                    </div>
                  );
                })}
              </>
            )}

          {reportContent.reviewContent.response.length === 0 &&
            reportContent.reviewContent.pendingResponse.length === 0 && (
              <ReviewResponse
                visitId={reportContent.visitId}
                review={reportContent.reviewContent}
              />
            )}
        </>
      )}
    </>
  );
};

export default ReviewReport;
