import { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
} from "@material-ui/core";
import { DesktopViewStyles } from "./styles/desktopViewStyles";
import i18n from "../../localizations/i18n";
import InboxList from "../inboxList/inboxList";
import { useDispatch, useSelector } from "react-redux";
import {
  SelectedCase,
  SelectedCaseMessageCount,
  SelectedVisitTaskCount,
  SelectedVisitCanUseGuestContact,
  SelectedVisitHasMediaOrReceipts,
  SelectedVisitId,
  SelectedVisitType,
  SelectedVisitBrandId,
} from "./state/selectors";
import IsAuthorised from "../../authorisation/permissions";
import { PermissionType } from "../../authorisation/permissionsUtils";
import InboxTaskDetails from "../inboxTaskDetails/inboxTaskDetails";
import InboxMedia from "../inboxMedia/inboxMedia";
import InboxReport from "../inboxReport/inboxReport";
import TabPanel from "./components/tabPanel";
import TabLabel from "./components/tabLabel";
import { getUrlQueryValueNumber } from "../../utils/urlParser";
import { updateCurrentFiltersEntry } from "../../state/actions/FilterSets-Actions";
import { HubFilterType, IVisitIdFilter } from "../../state/types/FilterSets";
import InboxGuestContact from "../InboxGuestContact/inboxGuestContact";
import InboxTaskScope, { TaskScope } from "../inboxTasksScope/inboxTasksScope";
import TaskCategoriesLoader from "./components/taskCategoriesLoader";
import TaskManagersLoader from "./components/taskManagersLoader";
import { MobileViewStyles } from "./styles/mobileViewStyles";
import { HGEMVisitType } from "../../utils/hgem";
import { fetchReviewResponseTemplates } from "../../state/actions/VisitReport-Actions";
import InboxAppeals from "../InboxAppeals/inboxAppeals";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import { useCanAccessAppeal } from "../../state/hooks/appeals/useCanAccessAppeal";
import { useClientId } from "../../state/hooks/clients/useClientId";
import { voucherDetails } from "../../state/hooks";
import { voucherConfiguration } from "../../state/actions/Vouchers-Actions";
import { IAppealsAcess } from "../../state/types/Appeals";
import ConversationVisitView from "./conversationVisitView";
import { ConversationsViewStyles } from "../conversationsViews/styles";
import { ClientBorderThreshold } from "../../state/api";
import { PageTabsStyles } from "../_common/pageTabs/styles/pageTabsStyles";

interface IProps {
  visitTypes: string[];
  pageRef: string;
  isMobileView: boolean;
  appealsFeatureActive: boolean | undefined;
  hasAppealsAccess: IAppealsAcess | undefined;
  borderColorThresholds: ClientBorderThreshold | undefined;
}

export enum TabIndex {
  VisitReport = 0,
  Media = 1,
  Tasks = 2,
  GuestContact = 3,
  Appeals = 4,
}

const InboxView = (props: IProps) => {
  const classes = props.isMobileView ? MobileViewStyles() : DesktopViewStyles();
  const tabClassses = PageTabsStyles();
  const conversationsViewStyles = ConversationsViewStyles();
  const dispatch = useDispatch();
  const clientId = useClientId();
  const history = useHistory();
  const selectedVisitId = useSelector(SelectedVisitId);
  const selectedVisitBrandId = useSelector(SelectedVisitBrandId);
  const queryVisitId = getUrlQueryValueNumber("visitid");
  const [updateKey, setUpdateKey] = useState(0);
  const [quarantineSuccessDialogOpen, setQuarantineSuccessDialogOpen] =
    useState(false);

  const queryInboxTab = getUrlQueryValueNumber("inboxtab");
  const appealsAccess = useCanAccessAppeal({
    clientId: clientId,
    visitId: selectedVisitId,
  });
  const [tabIndex, setTabIndex] = useState(queryInboxTab ?? 0);
  const [userCanReadGuestDetails, setUserCanReadGuestDetails] = useState(false);
  const [
    userCanAccessGuestCommunications,
    setUserCanAccessGuestCommunications,
  ] = useState(false);

  const selectedVisitType = useSelector(SelectedVisitType);
  const selectedVisitCanUseContact = useSelector(
    SelectedVisitCanUseGuestContact
  );
  const selectedVisitTaskCount = useSelector(SelectedVisitTaskCount);
  const selectedVisitHasMediaOrReceipts = useSelector(
    SelectedVisitHasMediaOrReceipts
  );
  const messageCount = useSelector(SelectedCaseMessageCount);
  const selectedCase = useSelector(SelectedCase);

  const currentlySelectedTab = () => {
    if (selectedCase && (!queryVisitId || selectedCase.vId === queryVisitId)) {
      if (!selectedVisitHasMediaOrReceipts && tabIndex === TabIndex.Media) {
        setTabIndex(TabIndex.VisitReport);
      }

      if (!selectedVisitCanUseContact && tabIndex === TabIndex.GuestContact) {
        setTabIndex(TabIndex.VisitReport);
      }

      if (
        appealsAccess &&
        !appealsAccess.isPermitted &&
        tabIndex === TabIndex.Appeals
      ) {
        setTabIndex(TabIndex.VisitReport);
      }
    }

    return tabIndex;
  };

  const voucherConfig = voucherDetails({ clientId: clientId });

  useEffect(() => {
    if (voucherConfig) {
      dispatch(voucherConfiguration(voucherConfig));
    }
  }, [dispatch, voucherConfig]);
  useEffect(() => {
    dispatch(fetchReviewResponseTemplates(selectedVisitBrandId));
  }, [dispatch, selectedVisitBrandId, selectedVisitId]);

  useEffect(() => {
    dispatch(
      updateCurrentFiltersEntry(HubFilterType.VisitId, (x: IVisitIdFilter) => {
        x.value = queryVisitId;
      })
    );
  }, [dispatch, queryVisitId]);

  useEffect(() => {
    const checkUserCanReadGuestDetails = async () => {
      const userCanReadDetails = await IsAuthorised(
        PermissionType.ReadGuestDetails
      );
      setUserCanReadGuestDetails(userCanReadDetails);
    };
    const checkUserCanAccessGuestCommunications = async () => {
      const userPermission = await IsAuthorised(
        PermissionType.GuestCommunicationDisabled
      );

      setUserCanAccessGuestCommunications(userPermission);
    };
    checkUserCanAccessGuestCommunications();
    checkUserCanReadGuestDetails();
  });

  useEffect(() => {
    const currentQueryParameters = queryString.parse(window.location.search);
    const newQueryParameters = {
      ...currentQueryParameters,
      inboxtab: tabIndex,
    };
    history.push({ search: queryString.stringify(newQueryParameters) });
  }, [tabIndex]);

  const tabContainerClasses =
    selectedVisitType === HGEMVisitType.Conversation
      ? `${classes.tabsContainer} ${conversationsViewStyles.conversationsContainer}`
      : classes.tabsContainer;

  const appealsTabActive =
    props.appealsFeatureActive && appealsAccess && appealsAccess.isPermitted;
  const mediaTabActive = selectedVisitHasMediaOrReceipts;
  const guestContactTabActive =
    selectedVisitCanUseContact &&
    userCanReadGuestDetails &&
    userCanAccessGuestCommunications;

  useEffect(() => {
    if (
      (!mediaTabActive && tabIndex === TabIndex.Media) ||
      (!appealsTabActive && tabIndex === TabIndex.Appeals) ||
      (!guestContactTabActive && tabIndex === TabIndex.GuestContact)
    ) {
      setTabIndex(TabIndex.VisitReport);
    }
  }, [selectedVisitId]);

  const onQuarantineSuccess = () => {
    setUpdateKey(updateKey + 1);
    setQuarantineSuccessDialogOpen(true);
  };

  return (
    <div className={classes.container} key={updateKey}>
      <TaskCategoriesLoader />
      <TaskManagersLoader />
      <div className={classes.listOuterContainer}>
        <InboxList
          key={updateKey}
          visitTypes={props.visitTypes}
          requestedVisitId={queryVisitId}
          listHeightOffset={250}
          pageRef={props.pageRef}
          showAsPopupList={props.isMobileView}
          borderColorThresholds={props.borderColorThresholds}
        />
      </div>
      <div className={tabContainerClasses}>
        <Tabs
          className={classes.tabs}
          variant="scrollable"
          scrollButtons={props.isMobileView ? "on" : "off"}
          indicatorColor="secondary"
          classes={{ indicator: tabClassses.blankIndicator }}
          onChange={(_, value) => setTabIndex(value)}
          value={currentlySelectedTab()}
        >
          <Tab
            value={TabIndex.VisitReport}
            classes={{
              selected: tabClassses.selectedTab,
              root: tabClassses.inactiveTab,
            }}
            label={
              selectedVisitType === HGEMVisitType.Conversation
                ? i18n.translate("INBOX_TABS_Conversation")
                : i18n.translate("INBOX_TABS_VisitReport")
            }
          />
          {selectedVisitHasMediaOrReceipts && (
            <Tab
              value={TabIndex.Media}
              classes={{
                selected: tabClassses.selectedTab,
                root: tabClassses.inactiveTab,
              }}
              label={
                <TabLabel
                  labelText={i18n.translate("INBOX_TABS_Media")}
                  showBadge={selectedVisitHasMediaOrReceipts}
                />
              }
            />
          )}

          <Tab
            value={TabIndex.Tasks}
            classes={{
              selected: tabClassses.selectedTab,
              root: tabClassses.inactiveTab,
            }}
            label={
              <TabLabel
                labelText={i18n.translate("INBOX_TABS_Tasks")}
                showBadge={selectedVisitTaskCount > 0}
              />
            }
          />
          {(selectedVisitType === HGEMVisitType.Review ||
            selectedVisitType === HGEMVisitType.Conversation ||
            !selectedVisitCanUseContact ||
            !userCanReadGuestDetails ||
            !userCanAccessGuestCommunications) === false && (
            <Tab
              value={TabIndex.GuestContact}
              classes={{
                selected: tabClassses.selectedTab,
                disabled: tabClassses.disabledTab,
                root: tabClassses.inactiveTab,
              }}
              label={
                <TabLabel
                  labelText={i18n.translate("INBOX_TABS_GuestCommunications")}
                  showBadge={!props.isMobileView && messageCount > 0}
                />
              }
            />
          )}

          {props.appealsFeatureActive &&
            appealsAccess &&
            appealsAccess.isPermitted && (
              <Tab
                value={TabIndex.Appeals}
                classes={{
                  selected: tabClassses.selectedTab,
                  disabled: tabClassses.disabledTab,
                  root: tabClassses.inactiveTab,
                }}
                label={
                  <TabLabel
                    labelText={i18n.translate("INBOX_TABS_Appeals")}
                    showBadge={false}
                  />
                }
              />
            )}
        </Tabs>
        {tabIndex === TabIndex.VisitReport && (
          <div className={classes.visitReport}>
            <TabPanel value={tabIndex} index={TabIndex.VisitReport}>
              <>
                {selectedVisitType !== HGEMVisitType.Conversation && (
                  <InboxReport
                    visitId={selectedVisitId}
                    visitType={selectedVisitType}
                    updateSelectedVisit={onQuarantineSuccess}
                  />
                )}
                {selectedVisitType === HGEMVisitType.Conversation && (
                  <ConversationVisitView
                    visitId={selectedVisitId}
                    isMobileView={props.isMobileView}
                  />
                )}
              </>
            </TabPanel>
          </div>
        )}

        <div style={{ height: "80vh", overflowY: "scroll", overflowX: "clip" }}>
          <TabPanel value={tabIndex} index={TabIndex.Tasks}>
            <>
              <InboxTaskScope scope={TaskScope.VisitId}>
                <InboxTaskDetails
                  visitId={selectedVisitId}
                  case={selectedCase}
                  showTaskSelector={true}
                  isMobileView={props.isMobileView}
                />
              </InboxTaskScope>
            </>
          </TabPanel>
          <TabPanel value={tabIndex} index={TabIndex.GuestContact}>
            <>
              {userCanReadGuestDetails && (
                <InboxGuestContact
                  visitId={selectedVisitId}
                  case={selectedCase}
                  showTaskSelector={true}
                  isMobileView={props.isMobileView}
                />
              )}
            </>
          </TabPanel>

          <TabPanel value={tabIndex} index={TabIndex.Media}>
            <InboxMedia visitId={selectedVisitId} />
          </TabPanel>
          <InboxTaskScope scope={TaskScope.VisitId}>
            <>
              {props.appealsFeatureActive && appealsAccess && (
                <TabPanel value={tabIndex} index={TabIndex.Appeals}>
                  <InboxAppeals visitId={selectedVisitId} />
                </TabPanel>
              )}
            </>
          </InboxTaskScope>
        </div>
      </div>
      <Dialog
        open={quarantineSuccessDialogOpen}
        onClose={() => setQuarantineSuccessDialogOpen(false)}
      >
        <DialogTitle>{i18n.translate("COMMON_SUCCESS")}</DialogTitle>
        <DialogContent>
          {i18n.translate("MORE_ACTIONS_MENU_Quarantine_Success_Message")}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setQuarantineSuccessDialogOpen(false)}>
            {i18n.translate("FORM_FIELD_ERROR_Close")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default InboxView;
