import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import i18n from "../../../localizations/i18n";

type Props = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const QuarantineErrorDialog = (props: Props) => {
  const { isOpen, setIsOpen } = props;

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {i18n.translate("COMMON_ERROR")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          style={{ color: "inherit" }}
        >
          {i18n.translate("MORE_ACTIONS_MENU_Quarantine_Error_Message")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsOpen(false)}>
          {i18n.translate("FORM_FIELD_ERROR_Close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QuarantineErrorDialog;
