import axios, { AxiosResponse } from "axios";
import { IVisitDetail } from "../types/VisitDetails";
import { IGetVisitDetailsQuery } from "../types/VisitDetailsQuery";

export interface IVisitDetailsResponse {
  visits: IVisitDetail[];
  totalCount: number;
}

export interface IVisitTasksCountResponse {
  R: number;
  C: number;
}

export const getVisitsApi = (
  clientId: number,
  query: IGetVisitDetailsQuery
): Promise<AxiosResponse<IVisitDetailsResponse>> => {
  return axios.post<IVisitDetailsResponse>(
    `${process.env.REACT_APP_HUB_API_BASEURL}VisitReport/Client/${clientId}/Search`,
    query
  );
};

export const getTaskCountsApi = (
  visitIds: number[]
): Promise<AxiosResponse<IVisitTasksCountResponse[]>> => {
  return axios.post<IVisitTasksCountResponse[]>(
    `${process.env.REACT_APP_CASE_MANAGEMENT_API_BASEURL}OpenStatus/GetStatuses`,
    visitIds
  );
};

export const getVisitMediaApi = (
  clientId: number,
  visitId: number
): Promise<AxiosResponse<string[]>> => {
  return axios.get<string[]>(
    `${process.env.REACT_APP_VISIT_DETAILS_API_BASEURL}VisitPhotos/${clientId}/${visitId}`
  );
};

export const getVisitReceiptsApi = (
  clientId: number,
  visitId: number
): Promise<AxiosResponse<string[]>> => {
  return axios.get<string[]>(
    `${process.env.REACT_APP_VISIT_DETAILS_API_BASEURL}VisitReceipts/${clientId}/${visitId}`
  );
};

export const postReviewResponseApi = (
  clientId: number,
  visitId: number,
  reviewId: string,
  responseText: string
): Promise<AxiosResponse<string>> => {
  return axios.post<string>(
    `${process.env.REACT_APP_HUB_API_BASEURL}VisitReport/Client/${clientId}/PostResponse`,
    {
      ReviewId: reviewId,
      ResponseText: responseText,
      VisitId: visitId,
    }
  );
};

export const postQuarantineVisitApi = (
  clientId: number,
  visitId: number
): Promise<AxiosResponse<string[]>> => {
  return axios.post<string[]>(
    `${process.env.REACT_APP_HUB_API_BASEURL}VisitQuarantine/Client/${clientId}/SelfQuarantine/${visitId}`
  );
};
