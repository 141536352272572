const strings = {
  GLOBAL_Menu_Open: "Ouvrir le Menu",
  GLOBAL_Menu_Close: "Fermer le Menu",
  GLOBAL_Logout: "Déconnexion",
  GLOBAL_BROWSER_TITLE: "HGEM Hub:",
  GLOBAL_HGEM: "HGEM",

  USER_MENU_MY_ACCOUNTS: "Mes comptes",

  COMMON_BUTTON_TEXT_submit: "Envoi",
  COMMON_TEXT_CATEGORY: "Catégorie",
  COMMON_TEXT_REVIEW_DECISION: "Revoir décision",
  COMMON_BUTTON_TEXT_next: "Vérifier & envoyer",
  COMMON_CURRENCY_SYMBOL_UK: "£",
  COMMON_CHARACTERS_REMAINING: "Caractères restants",
  COMMON_DATE_AND_TIME_FORMAT: "ddd DD MMM YY",
  COMMON_24_HR_TIME_FORMAT: "HH:mm",
  COMMON_SUCCESS: "Succès",
  COMMON_CANCEL: "Annuler",
  COMMON_ERROR: "Erreur",

  ACCOUNT_SELECTION_SwitchAccount: "Passer à un autre compte",
  ACCOUNT_SELECTION_ToolbarButton_Title: "Mes comptes et marques",
  ACCOUNT_SELECTION_DialogTitle_Accounts: "Mes comptes:",
  ACCOUNT_SELECTION_DialogTitle_Brands: "Mes marques:",
  ACCOUNT_SELECTION_DialogHint_Accounts:
    "Vous pouvez basculer entre vos comptes disponibles en sélectionnant une des options ci-dessous.",
  ACCOUNT_SELECTION_DialogHint_Brands:
    "Veuillez sélectionner une de vos marques disponibles.",
  ACCOUNT_SELECTION_Apply_Brand_Selection: "Appliquer",
  ACCOUNT_SELECTION_Cancel_Brand_Selection: "Annuler",
  ACCOUNT_SELECTION_SelectAll: "Sélectionner tout",
  ACCOUNT_SELECTION_DeselectAll: "Désélectionner tout ",
  ACCOUNT_SELECTION_SelectAll_Split: "/",

  SUPPORT_OPTION_Title: "Base de connaissances & support",
  SUPPORT_OPTION_SupportLink: "https://support.hgem.com",

  // Report Titles: Match with report/page configuration from Hub API
  "Report: MyDashboard": "Mon tableau de bord",
  "Report: AuditOverview": "Aperçu audit",
  "Report: SelfAudits": "Auto-audits",
  "Report: ProjectAudits": "Audits projets",
  "Report: DishSpecs": "Spécifications plats",
  "Report: AuditQuestions": "Questions audit",
  "Report: AuditQuestionsHeatMap": "Carte thermique questions audit",
  "Report: AssessorComments": "Commentaires évaluateur",
  "Report: QuestionFailures": "Carte thermique échecs bonus",
  "Report: AuditSections": "Carte thermique sections audit",
  "Report: Categories": "Carte thermique catégories",
  "Report: FeedbackOverview": "Aperçu feedback",
  "Report: FeedbackQuestions": "Questions feedback",
  "Report: ReviewRatings": "Cotes pages d’avis",
  "Report: NetPromoterScore": "Net Promoter Score",
  "Report: DishRatings": "Cotes plats",
  "Report: SurveyQuestions": "Carte thermique questions sondage",
  "Report: SurveySections": "Carte thermique sections sondage",
  "Report: ReviewHeatmap": "Carte thermique pages d’avis",
  "Report: Leaderboards": "Tableaux des scores",
  "Report: Benchmarking": "Analyse comparative",
  "Report: TeamEngagementTracker": "Suivi implication équipe",
  "Report: ManagementStatistics": "Statistiques visites",
  "Report: ManagementFulfilment": "Réalisation visites",
  "Report: TrackAndTrace": "Suivi",
  "Report: KeyMeasures": "Mesures clés",
  "Report: KeyMeasuresBreakdown": "Analyse mesures clés",
  "Report: RawData": "Données brutes",
  "Report: GuestContactDetails": "Coordonnées client",
  "Report: ServerScores": "Scores Serveurs",
  "Report: ExponeaExtract": "Exponea Extract",
  "Report: TaskCentreStatistics": "Statistiques Tâches",
  "Report: Sentiment": "Opinion",
  "Report: DeliveryReviews": "Avis livraisons",
  "Report: NPSStatistics": "Statistiques NPS",
  "Report: NPSAlignment": "Alignement NPS",

  HOMEPAGE_Title: "Quoi de neuf",

  // Components
  SESSION_MANAGER_Refresh_Message:
    "Une mise à jour du Hub nécessite que vous rafraîchissiez votre session.",
  SESSION_MANAGER_Refresh_Button: "Rafraîchir session",
  SESSION_MANAGER_Incoming_update_message:
    "Une nouvelle version du Hub sera disponible dans les prochaines 5 à 10 minutes. Il vous sera alors demandé de rafraîchir votre session. Ceci déclenchera une déconnexion et il vous faudra vous reconnecter afin que les mises à jour prennent effet.",

  PERIOD_PICKER_Preset_Selections: "Sélectionner par préréglage",
  PERIOD_PICKER_OPTION_CurrentPeriod: "Période en cours",
  PERIOD_PICKER_OPTION_LastCompletePeriod: "Dernière période complète",
  PERIOD_PICKER_OPTION_LastTwoCompletePeriods: "2 dernières périodes complètes",
  PERIOD_PICKER_OPTION_LastThreeCompletePeriods:
    "3 dernières périodes complètes",
  PERIOD_PICKER_OPTION_CurrentYearToDate: "Année en cours",
  PERIOD_PICKER_OPTION_PreviousYear: "Année précédente",
  PERIOD_PICKER_OPTION_Filter_Periods: "Filtrer périodes",

  DATE_RANGE_PICKER_Display_Format: "DD/MM/YYYY",
  DATE_RANGE_PICKER_Start_Date: "Date de début",
  DATE_RANGE_PICKER_Change_Start_Date: "Changer la date de début",
  DATE_RANGE_PICKER_End_Date: "Date de fin",
  DATE_RANGE_PICKER_Change_End_Date: "Changer la date de fin",
  DATE_RANGE_PICKER_Apply: "Appliquer",
  DATE_RANGE_PICKER_Cancel: "Annuler",

  DATE_PERIOD_PICKER_Dates: "Dates",
  DATE_PERIOD_PICKER_RelativeDate: "Par rapport à aujourd’hui",
  DATE_PERIOD_PICKER_Custom_Date_Range: "Personnaliser période",
  DATE_PERIOD_PICKER_Reporting_Period: "Périodes de reporting",
  DATE_PERIOD_PICKER_LastTwelveWeeks: "12 dernières semaines",
  DATE_PERIOD_PICKER_LastFourWeeks: "4 dernières semaines",
  DATE_PERIOD_PICKER_LastTwoWeeks: "2 dernières semaines",
  DATE_PERIOD_PICKER_LastWeek: "7 derniers jours",
  DATE_PERIOD_PICKER_CurrentPeriod: "Période en cours",
  DATE_PERIOD_PICKER_LastCompletePeriod: "Dernière période complétée",
  DATE_PERIOD_PICKER_LastTwoCompletePeriods: "2 dernières périodes complétées",
  DATE_PERIOD_PICKER_LastThreeCompletePeriods:
    "3 dernières périodes complétées",
  DATE_PERIOD_PICKER_CurrentYearToDate: "Année en cours",
  DATE_PERIOD_PICKER_PreviousYear: "Année précédente",
  DATE_PERIOD_PICKER_Tooltip_Date_Format: "ddd DD MMM YY",
  DATE_PERIOD_PICKER_Periods_Selected: "Périodes sélectionnées:",
  DATE_PERIOD_PICKER_Num_Periods_Selected: "Période(s) sélectionnée(s)",
  DATE_PERIOD_PICKER_Num_Reporting_Periods_Selected:
    "Période(s) sélectionnée(s)",
  DATE_PERIOD_PICKER_Calendar: "Calendrier",
  DATE_PERIOD_PICKER_Visit_Schedules: "Plannings visites",
  DATE_PERIOD_PICKER_Cancel: "Annuler",
  DATE_PERIOD_PICKER_Which_do_I_Choose: "Quelle option dois-je choisir ?",
  DATE_PERIOD_PICKER_Weeks_Selected: "Période(s) sélectionnée(s)",
  DATE_PERIOD_PICKER_Undefined_Brand_Name: "Par défaut",

  DATE_PERIOD_PICKER_HELP_ReportingPeriod_Title: "Périodes de reporting:",
  DATE_PERIOD_PICKER_HELP_VisitScheule_Title: "Plannings de visites:",
  DATE_PERIOD_PICKER_HELP_Relative_Title: "Par rapport à aujourd’hui:",
  DATE_PERIOD_PICKER_HELP_Custom_Title: "Personnaliser période :",
  DATE_PERIOD_PICKER_HELP_ReportingPeriod_Description:
    "Sélectionnez rapidement une série de périodes, y compris des semaines, mois ou trimestres.",
  DATE_PERIOD_PICKER_HELP_VisitScheule_Description:
    "Sélectionnez à partir de plannings d’audits configurés et personnalisez les périodes.",
  DATE_PERIOD_PICKER_HELP_Relative_Description:
    "Sélection rapide de périodes récentes, toutes incluant ‘aujourd’hui’.",
  DATE_PERIOD_PICKER_HELP_Custom_Description:
    "Sélectionnez une période personnalisée en précisant une date de début et de fin.",
  DATE_PERIOD_PICKER_Back: "Retour aux options",

  BRAND_PICKER_LABEL: "Marques",
  BRAND_PICKER_SELECTED: "Marques sélectionnées",

  SEGMENT_PICKER_LABEL: "Segments",
  SEGMENT_PICKER_SELECTED: "Segments sélectionnés",
  SEGMENT_PICKER__ALL_SELECTED: "Tous sélectionnés",

  LOCATION_PICKER_All_Selected: "Tous sélectionnés",
  LOCATION_PICKER_Selected: "Sélectionné",
  LOCATION_PICKER_Locations_Selected: "Lieu(x) sélectionné(s):",
  LOCATION_PICKER_All_Locations_Selected: "Tous lieux sélectionnés",
  LOCATION_PICKER_Locations: "Lieux",
  LOCATION_PICKER_Preset_Selections: "Sélectionner par type de lieu",
  LOCATION_PICKER_Blank_Segment_Name: "* Type de lieu inconnu *",

  AREA_REGION_PICKER_Area_and_Regions: "Secteurs et régions",
  AREA_REGION_PICKER_All_Areas_Selected: "Tous secteurs/régions sélectionnés",
  AREA_REGION_PICKER_Areas_Selected: "Secteur(s) sélectionné(s)",
  AREA_REGION_PICKER_Selected: "Sélectionné",
  AREA_REGION_PICKER_All_Selected: "Tous sélectionnés",

  LOCATION_SEGMENT_PICKER_Location_Type: "Type de lieu",
  LOCATION_SEGMENTS_PICKER_All_Selected: "Tous sélectionnés",
  LOCATION_SEGMENTS_PICKER_Selected: "Sélectionné",
  LOCATION_SEGMENT_PICKER_Select_All_Option: "Sélectionner tout",

  GENERIC_TREE_VIEW_All_selected: "Tous sélectionnés",
  GENERIC_TREE_VIEW_selected: "Sélectionné",
  GENERIC_TREE_VIEW_Apply: "Appliquer",
  GENERIC_TREE_VIEW_Cancel: "Annuler",
  GENERIC_TREE_VIEW_ExpandAll: "Développer tout",
  GENERIC_TREE_VIEW_Collapse_All: "Réduire tout",
  GENERIC_TREE_VIEW_Select_All: "Sélectionner tout",
  GENERIC_TREE_VIEW_Clear_Selections: "Effacer les sélections",
  GENERIC_TREE_VIEW_Showing: "Affichage",
  GENERIC_TREE_VIEW_Of: "de/des",
  GENERIC_TREE_VIEW_Items: "Objets",

  GENERIC_MULTI_SELECT_All_selected: "Tous sélectionnés",
  GENERIC_MULTI_SELECT_Selected: "Sélectionné",
  GENERIC_MULTI_SELECT_Select_All: "Sélectionner tout",
  GENERIC_MULTI_SELECT_Clear_Selections: "Effacer les sélections",
  GENERIC_MULTI_SELECT_Apply: "Appliquer",
  GENERIC_MULTI_SELECT_Cancel: "Annuler",

  LOCATION_RANK_PICKER_Location_Rank: "Classer par lieu",

  MORE_ACTIONS_MENU_Actions: "Actions",
  MORE_ACTIONS_MENU_Quarantine: "Supprimer",
  MORE_ACTIONS_MENU_Quarantine_Dialog_Title: "Supprimer la visite",
  MORE_ACTIONS_MENU_Quarantine_Dialog_Message_1:
    "Etes-vous sûr de vouloir supprimer cette visite de The Hub?",
  MORE_ACTIONS_MENU_Quarantine_Dialog_Message_2:
    "Elle ne sera plus incluse dans aucune analyse.",
  MORE_ACTIONS_MENU_Quarantine_Dialog_Message_3:
    "Cette action ne peut pas être annulée.",
  MORE_ACTIONS_MENU_Quarantine_Confirm: "Supprimer",
  MORE_ACTIONS_MENU_Quarantine_Success_Message: "Visite supprimée avec succès.",
  MORE_ACTIONS_MENU_Quarantine_Error_Message:
    "Une erreur s'est produite lors de la suppression de cette visite. Si cela persiste, veuillez contacter le support.",

  VISIT_TYPE_PICKER_All_Selected: "Tous sélectionnés",
  VISIT_TYPE_PICKER_Selected: "Sélectionné",
  VISIT_TYPE_PICKER_Visit_Type: "Type de visite",
  VISIT_TYPE_PICKER_Select_All_Option: "Sélectionner tout",
  VISIT_TYPE_OPTION_MV: "Visite mystère",
  VISIT_TYPE_OPTION_OF: "Sondage en ligne",
  VISIT_TYPE_OPTION_CC: "Fiche de commentaire",
  VISIT_TYPE_OPTION_RV: "Page d’avis",
  VISIT_TYPE_OPTION_ES: "Sondage employé(e)",
  VISIT_TYPE_OPTION_TA: "Audit téléphonique",
  VISIT_TYPE_OPTION_PA: "Vérification du projet",
  VISIT_TYPE_OPTION_DA: "Audit de livraison",
  VISIT_TYPE_OPTION_DR: "Avis livraison",
  VISIT_TYPE_OPTION_PS: "Sondage produit",
  VISIT_TYPE_OPTION_EF: "Feedback externe",
  VISIT_TYPE_OPTION_TT: "Suivi",
  VISIT_TYPE_OPTION_RC: "Audit interne",
  VISIT_TYPE_OPTION_INVALID_VISIT_TYPE: "Type de visite inconnu",

  VISIT_SOURCE_OPTION_brownbook: "Brownbook",
  VISIT_SOURCE_OPTION_complaint: "Réclamation",
  VISIT_SOURCE_OPTION_facebook: "Facebook",
  VISIT_SOURCE_OPTION_factual: "Factual",
  VISIT_SOURCE_OPTION_foursquare: "FourSquare",
  VISIT_SOURCE_OPTION_google: "Google",
  VISIT_SOURCE_OPTION_hotfrog: "HotFrog",
  VISIT_SOURCE_OPTION_scoot: "Scoot",
  VISIT_SOURCE_OPTION_thomsonlocal: "Thomson Local",
  VISIT_SOURCE_OPTION_touchlocal: "Touch Local",
  VISIT_SOURCE_OPTION_tripadvisor: "TripAdvisor",
  VISIT_SOURCE_OPTION_yahoo: "Yahoo! Local",
  VISIT_SOURCE_OPTION_yell: "Yell",
  VISIT_SOURCE_OPTION_yelp: "Yelp",
  VISIT_SOURCE_OPTION_other: "Autre",
  VISIT_SOURCE_OPTION_opentable: "OpenTable",
  VISIT_SOURCE_OPTION_bookatable: "Bookatable",
  "VISIT_SOURCE_OPTION_Design My Night": "Design My Night",
  VISIT_SOURCE_OPTION_zomato: "Zomato",
  VISIT_SOURCE_OPTION_expedia: "Expedia",
  VISIT_SOURCE_OPTION_booking: "Booking.com",
  VISIT_SOURCE_OPTION_ResDiary: "ResDiary",
  VISIT_SOURCE_OPTION_DishCult: "Dish Cult",
  VISIT_SOURCE_OPTION_trustpilot: "Trustpilot",
  VISIT_SOURCE_OPTION_Deliveroo: "Deliveroo Reviews",
  VISIT_SOURCE_OPTION_peachcommentcards: "Peach Comment Cards",
  VISIT_SOURCE_OPTION_pretasia: "Pret Asia",
  VISIT_SOURCE_OPTION_pretmiddleeast: "Pret Middle East",
  VISIT_SOURCE_OPTION_pretus: "Pret US",
  VISIT_SOURCE_OPTION_foodpanda: "FoodPanda",
  VISIT_SOURCE_OPTION_Email: "Email",
  VISIT_SOURCE_OPTION_Website: "Site internet",
  VISIT_SOURCE_OPTION_Phone: "Téléphone",
  VISIT_SOURCE_OPTION_Twitter: "Twitter",
  VISIT_SOURCE_OPTION_Instagram: "Instagram",
  VISIT_SOURCE_OPTION_Kiosk: "Kiosque",
  VISIT_SOURCE_OPTION_Restaurant: "Restaurant",
  "VISIT_SOURCE_OPTION_Smart Order": "Smart Order",
  VISIT_SOURCE_OPTION_Counter: "Comptoir",
  VISIT_SOURCE_OPTION_Delivery: "Livraison",
  "VISIT_SOURCE_OPTION_Deliveroo DS": "Deliveroo",
  "VISIT_SOURCE_OPTION_Uber Eats DS": "Uber Eats",
  "VISIT_SOURCE_OPTION_Just Eat DS": "Just Eat",
  "VISIT_SOURCE_OPTION_Click and Collect": "Cliquer et collecter",
  VISIT_SOURCE_OPTION_Takeaway: "Emporter",
  "VISIT_SOURCE_OPTION_Eat In": "Sur place",
  VISIT_SOURCE_OPTION_Reservation: "Réservation",
  VISIT_SOURCE_OPTION_WIFI: "WIFI",
  "VISIT_SOURCE_OPTION_Voucher Redemption": "Utilisation bon d’achat",
  VISIT_SOURCE_OPTION_Receipt: "Reçu",
  "VISIT_SOURCE_OPTION_Pay at Table": "Paiement à table",
  VISIT_SOURCE_OPTION_GrubHub: "GrubHub",
  VISIT_SOURCE_OPTION_Seamless: "Seamless",
  "VISIT_SOURCE_OPTION_The Fork": "The Fork",
  VISIT_SOURCE_OPTION_Trip: "Trip",
  VISIT_SOURCE_OPTION_STN: "STN",
  VISIT_SOURCE_OPTION_Loyalty: "Loyalty",
  VISIT_SOURCE_OPTION_Orderbee: "Orderbee",
  VISIT_SOURCE_OPTION_pretfrance: "Pret France",
  VISIT_SOURCE_OPTION_pretbelgium: "Pret Belgium",
  VISIT_SOURCE_OPTION_Orbitz: "Orbitz",
  VISIT_SOURCE_OPTION_Hotels: "Hotels.com",
  VISIT_SOURCE_OPTION_Travelocity: "Travelocity",
  "VISIT_SOURCE_OPTION_Just Eat": "Just Eat",
  "VISIT_SOURCE_OPTION_Uber Eats": "Uber Eats",

  QUESTIONNAIRE_TYPE_PICKER_All_Selected: "Tous sélectionnés",
  QUESTIONNAIRE_TYPE_PICKER_Selected: "Sélectionné",
  QUESTIONNAIRE_TYPE_PICKER_Questionnaire_Type: "Type de questionnaire",
  QUESTIONNAIRE_TYPE_PICKER_Select_All_Option: "Sélectionner tout",

  QUESTIONS_PICKER_Questions: "Questions",
  QUESTIONS_PICKER_Selected_Questions: "Questions sélectionnées",
  QUESTIONS_PICKER_Search: "Chercher",
  QUESTIONS_PICKER_Selected: "Sélectionné",
  QUESTIONS_PICKER_Apply: "Appliquer",
  QUESTIONS_PICKER_Cancel: "Annuler",
  QUESTIONS_PICKER_All_Selected: "Tous sélectionnés",
  QUESTIONS_PICKER_Selected_Tooltip: "Selectionné:",
  QUESTIONS_PICKER_Show_Full_Text: "Afficher texte integral de la question",

  QUESTION_SECTIONS_PICKER_All_Selected: "Tous sélectionnés",
  QUESTION_SECTIONS_PICKER_Selected: "Sélectionné",
  QUESTION_SECTIONS_PICKER_Sections: "Sections",
  QUESTION_SECTIONS_PICKER_Select_All_Option: "Tous sélectionnés",
  QUESTION_SECTIONS_PICKER_Show_Groupings: "Afficher types de questionnaires",

  QUESTION_CATEGORY_PICKER_All_Selected: "Tous sélectionnés",
  QUESTION_CATEGORY_PICKER_Selected: "Sélectionné",
  QUESTION_CATEGORY_PICKER_Question_Categrory: "Catégorie question",
  QUESTION_CATEGORY_PICKER_Select_All_Option: "Tous sélectionnés",

  QUESTION_BENCHMARKS_PICKER_All_Selected: "Tous sélectionnés",
  QUESTION_BENCHMARKS_PICKER_Selected: "Sélectionné",
  QUESTION_BENCHMARKS_PICKER_Benchmarks: "Références",
  QUESTION_BENCHMARKS_PICKER_Select_All_Option: "Tous sélectionnés",

  QUESTION_ANALYSIS_TAGS_PICKER_All_Selected: "Tous sélectionnés",
  QUESTION_ANALYSIS_TAGS_PICKER_Selected: "Sélectionné",
  QUESTION_ANALYSIS_TAGS_PICKER_Tags: "Tags questions",
  QUESTION_ANALYSIS_TAGS_PICKER_Select_All_Option: "Tous sélectionnés",

  QUESTION_NPS_PICKER_VALUES_Promoter: "Promoteur",
  QUESTION_NPS_PICKER_VALUES_Neutral: "Neutre",
  QUESTION_NPS_PICKER_VALUES_Detractor: "Détracteur",
  QUESTION_NPS_PICKER_NetPromoter: "Classification NPS",
  QUESTION_NPS_PICKER_All_Selected: "Tous sélectionnés",
  QUESTION_NPS_PICKER_Selected: "Sélectionné",
  QUESTION_NPS_PICKER_Select_All_Option: "Tous sélectionnés",

  VISIT_SCORE_RANGE_PICKER_Visit_Score: "Score visite",
  VISIT_SCORE_RANGE_PICKER_Range: "Gamme: ",
  VISIT_SCORE_RANGE_PICKER_Apply: "Appliquer",
  VISIT_SCORE_RANGE_PICKER_Cancel: "Annuler",

  QUESTION_SCORE_RANGE_PICKER_Question_Score: "Scores question",
  QUESTION_SCORE_RANGE_PICKER_Range: "Gamme: ",
  QUESTION_SCORE_RANGE_PICKER_Apply: "Appliquer",
  QUESTION_SCORE_RANGE_PICKER_Cancel: "Annuler",

  DAY_OF_WEEK_PICKER_All_Selected: "Tous sélectionnés",
  DAY_OF_WEEK_PICKER_Selected: "Sélectionné",
  DAY_OF_WEEK_PICKER_Day: "Jour",
  DAY_OF_WEEK_PICKER_Select_All_Option: "Sélectionner tout",
  DAY_OF_WEEK_PICKER_Monday: "Lundi",
  DAY_OF_WEEK_PICKER_Tuesday: "Mardi",
  DAY_OF_WEEK_PICKER_Wednesday: "Mercredi",
  DAY_OF_WEEK_PICKER_Thursday: "Jeudi",
  DAY_OF_WEEK_PICKER_Friday: "Vendredi",
  DAY_OF_WEEK_PICKER_Saturday: "Samedi",
  DAY_OF_WEEK_PICKER_Sunday: "Dimanche",

  DAY_PART_PICKER_All_Selected: "Tous sélectionnés",
  DAY_PART_PICKER_Selected: "Sélectionné",
  DAY_PART_PICKER_DayPart: "Partie de la journée",
  DAY_PART_PICKER_Select_All_Option: "Sélectionner tout",
  "DAY_PART_PICKER_All Day": "Toute la journée",
  DAY_PART_PICKER_Morning: "Matin",
  DAY_PART_PICKER_Lunch: "Lunch",
  DAY_PART_PICKER_Afternoon: "Après-midi",
  DAY_PART_PICKER_Evening: "Soirée",
  "DAY_PART_PICKER_Late Night": "Fin de soirée",
  "DAY_PART_PICKER_TOOLTIP_All Day": "",
  DAY_PART_PICKER_TOOLTIP_Morning: "5h00 – 11h30",
  DAY_PART_PICKER_TOOLTIP_Lunch: "11h30 – 14h30",
  DAY_PART_PICKER_TOOLTIP_Afternoon: "14h30 – 17h00",
  DAY_PART_PICKER_TOOLTIP_Evening: "17h00 – 0h00",
  "DAY_PART_PICKER_TOOLTIP_Late Night": "0h00 – 5h00",
  TASK_CENTRE_STATUS_PICKER_All_Selected: "Tous sélectionnés",
  TASK_CENTRE_STATUS_PICKER_Selected: "Sélectionné",
  TASK_CENTRE_STATUS_PICKER_Status: "État tâche",
  TASK_CENTRE_STATUS_PICKER_Select_All_Option: "Sélectionner tout",
  TASK_CENTRE_TASK_ID: "tâche id",
  TASK_CENTRE_CATEGORY_PICKER_All_Selected: "Tous sélectionnés",
  TASK_CENTRE_CATEGORY_PICKER_Selected: "Sélectionné",
  TASK_CENTRE_CATEGORY_PICKER_Category: "Catégorie",
  TASK_CENTRE_CATEGORY_PICKER_Select_All_Option: "Tous sélectionnés",

  TASK_CENTRE_PRIORITY_PICKER_All_Selected: "Tous sélectionnés",
  TASK_CENTRE_PRIORITY_PICKER_Selected: "Sélectionné",
  TASK_CENTRE_PRIORITY_PICKER_Priority: "Priorité",
  TASK_CENTRE_PRIORITY_PICKER_Select_All_Option: "Sélectionner tout",

  APPEAL_STATUS_PICKER_All_Selected: "Tous sélectionnés",
  APPEAL_STATUS_PICKER_Please_Select: "Veuillez sélectionner",
  APPEAL_STATUS_PICKER_Selected: "Sélectionné",
  APPEAL_STATUS_PICKER_Status: "État appel",
  TASK_STATUS_PICKER_Select_All_Option: "Tous sélectionnés",

  VISIT_ID_PICKER_VisitId: "Identifiant visite",
  VISIT_ID_PICKER_No_Id_Set: "n/a",
  VISIT_ID_PICKER_ClearSearch: "Effacer",
  VISIT_ID_PICKER_Apply: "Appliquer",
  VISIT_ID_PICKER_Cancel: "Annuler",

  VISIT_SOURCE_PICKER_Visit_Source: "Source visite",
  VISIT_SOURCE_PICKER_Select_All_Option: "Sélectionner tout",
  VISIT_SOURCE_PICKER_All_Selected: "Tous sélectionnés",
  VISIT_SOURCE_PICKER_Selected: "Sélectionné",

  VISIT_DISH_NAME_PICKER_All_Selected: "N’importe quel",
  VISIT_DISH_FILTER_label: "Nom du plat",
  VISIT_DISH_FILTER_NUMBER_SELECT_label: "Plat(s) sélectionné(s):",

  ANALYSIS_TAG_VALUE_PICKER_Feedback_Category: "Catégorie feedback",
  "ANALYSIS_TAG_VALUE_PICKER_Feedback Category": "Catégorie feedback",
  ANALYSIS_TAG_VALUE_PICKER_Feedback_Sub_Category: "Sous-catégorie",
  "ANALYSIS_TAG_VALUE_PICKER_Sub Category": "Sous-catégorie",
  ANALYSIS_TAG_VALUE_PICKER_Department: "Département",
  ANALYSIS_TAG_VALUE_PICKER_Room: "Salle/espace",
  ANALYSIS_TAG_VALUE_PICKER_Length_of_Service: "Années de service",
  "ANALYSIS_TAG_VALUE_PICKER_Length of Service": "Années de service",
  ANALYSIS_TAG_VALUE_PICKER_Select_All_Option: "Sélectionner tout",
  ANALYSIS_TAG_VALUE_PICKER_All_Selected: "Tous sélectionnés",
  ANALYSIS_TAG_VALUE_PICKER_Selected: "Sélectionné",

  TASK_ASSIGNEE_PICKER_All_Selected: "Tous sélectionnés",
  TASK_ASSIGNEE_PICKER_Selected: "Sélectionné",
  TASK_ASSIGNEE_PICKER_Assignee: "Task assignée à",
  TASK_ASSIGNEE_PICKER_Search: "Chercher",
  TASK_ASSIGNEE_PICKER_SearchByName: "Nom",
  TASK_ASSIGNEE_PICKER_Select_All_Option: "Sélectionner tout",
  TASK_CENTRE_NO_TASKS: "Cette visite n'a aucune tâche",

  VISIT_CONTACT_STATUS_PICKER_Contact_Status: "État contact",
  VISIT_CONTACT_STATUS_PICKER_PREFIX_ShowAll: "N’importe quel",
  VISIT_CONTACT_STATUS_PICKER_PREFIX_WithContact:
    " Sondage : Coordonnées client disponibles",
  VISIT_CONTACT_STATUS_PICKER_PREFIX_WithNoContact:
    " Sondage : Coordonnées client non-disponibles",
  VISIT_CONTACT_STATUS_PICKER_PREFIX_AwaitingContact:
    " Sondage : En attente de premier contact",
  VISIT_CONTACT_STATUS_PICKER_PREFIX_ContactMade: " Sondage : Contacté",
  VISIT_CONTACT_STATUS_PICKER_PREFIX_GuestReplied:
    " Sondage : Client a répondu",
  VISIT_CONTACT_STATUS_PICKER_PREFIX_ReviewUnresponded: " Avis : Pas réagi",
  VISIT_CONTACT_STATUS_PICKER_PREFIX_ReviewResponded: " Avis : Réagi",

  FILTERS_ERROR_LOADING: "Impossible de charger le filtre",
  FILTERS_RETRY:
    "Cliquez pour ressayer. Si le problème persiste, veuillez contacter notre centre de support",

  FILTER_CONFIGURATION_OPTIONS_Add_Filter: "Filtres",
  FILTER_ACCORDION_TITLE: "Afficher les filtres",

  FILTER_CONFIGURATION_OPTIONS_Add_Filters_Title: "Ajouter filtres:",
  FILTER_CONFIGURATION_OPTIONS_Add_Filters_Description:
    "Choisissez ci-dessous n’importe quelle combinaison de filtres supplémentaires. Ceux-ci seront appliqués en plus des filtres de date et de lieu.",
  FILTER_CONFIGURATION_OPTIONS_Add_Filters_Note:
    "Notez que certaines combinaisons filtre + rapport ne sont pas applicables. Dans ce cas, les options de filtre ci-dessous seront désactivées et n’auront aucun impact sur le rapport.",
  FILTER_CONFIGURATION_OPTIONS_Questions_and_Answers: "Questionnaires:",
  FILTER_CONFIGURATION_OPTIONS_InboxTasks: "Tâches et communications:",
  FILTER_CONFIGURATION_OPTIONS_Visit: "Visite:",
  FILTER_CONFIGURATION_OPTIONS_Scores: "Scores:",
  FILTER_CONFIGURATION_OPTIONS_Time: "Heure:",
  FILTER_CONFIGURATION_OPTIONS_Other: "Autre:",
  FILTER_CONFIGURATION_OPTIONS_Apply: "Appliquer",
  FILTER_CONFIGURATION_OPTIONS_Cancel: "Annuler",
  FILTER_CONFIGURATION_OPTIONS_ENUM_VisitType: "Type de visite",
  FILTER_CONFIGURATION_OPTIONS_ENUM_QuestionnaireType: "Type de questionnaire",
  FILTER_CONFIGURATION_OPTIONS_ENUM_Sections: "Sections questionnaire",
  FILTER_CONFIGURATION_OPTIONS_ENUM_Questions: "Questions",
  FILTER_CONFIGURATION_OPTIONS_ENUM_QuestionCategory: "Catégorie de question",
  FILTER_CONFIGURATION_OPTIONS_ENUM_Benchmarks: "Références",
  FILTER_CONFIGURATION_OPTIONS_ENUM_AnalysisTags: "Tags question",
  FILTER_CONFIGURATION_OPTIONS_ENUM_VisitScoreRange: "Score visite",
  FILTER_CONFIGURATION_OPTIONS_ENUM_QuestionScoreRange: "Score question",
  FILTER_CONFIGURATION_OPTIONS_ENUM_NPS: "Classification NPS",
  FILTER_CONFIGURATION_OPTIONS_ENUM_DayPart: "Partie de la journée",
  FILTER_CONFIGURATION_OPTIONS_ENUM_DayOfWeek: "Jour de la semaine",
  FILTER_CONFIGURATION_OPTIONS_ENUM_VisitAnalysisDepartment: "Département",
  FILTER_CONFIGURATION_OPTIONS_ENUM_VisitAnalysisRoom: "Salle/espace",
  FILTER_CONFIGURATION_OPTIONS_ENUM_VisitSource: "Source visite",
  FILTER_CONFIGURATION_OPTIONS_ENUM_FeedbackCategory: "Catégorie de feedback",
  FILTER_CONFIGURATION_OPTIONS_ENUM_FeedbackSubCategory:
    "Sous-catégorie de feedback",
  FILTER_CONFIGURATION_OPTIONS_ENUM_TaskCentreCategory: "Catégorie tâche",
  FILTER_CONFIGURATION_OPTIONS_ENUM_TaskCentrePriority: "Priorité tâche",
  FILTER_CONFIGURATION_OPTIONS_ENUM_LengthOfService: "Années de service",
  FILTER_CONFIGURATION_OPTIONS_ENUM_DishFilters: "Nom du plat",

  REPORT_DATA_EXPORT_Export_Data: "Exportation de données:",
  REPORT_DATA_EXPORT_Exports_Help_Message:
    "L’exportation est plafonnée à 30.000 lignes. S’il vous faut plus, veuillez contacter support@hgem.com",
  REPORT_DATA_EXPORT_Export: "Exporter",
  REPORT_DATA_EXPORT_Cancel: "Annuler",
  REPORT_DATA_EXPORT_File_Name: "Nom fichier",
  REPORT_DATA_EXPORT_FileName_HelpText:
    "Un nom de fichier ne peut contenir que des caractères alphanumériques, des traits de soulignement ou des traits d’union.",
  REPORT_DATA_EXPORT_Source: "Source",
  REPORT_DATA_EXPORT_FileDateFormat: "YYYY-MM-DD__HH-mm",
  REPORT_DATA_EXPORT_File_Extension_Adornment: ".CSV",
  REPORT_DATA_EXPORT_No_visuals_available:
    "Il n’y a pas de données exportables dans cette page de rapport. Veuillez essayer avec une autre page ou un autre rapport.",

  PAGE_TASKS_Print_Page: "Imprimer la page",
  PAGE_TASKS_Export_Data: "Exporter les données",
  // Power BI Data Model & Report Internals
  POWERBI_FILTER_Client_Id_TABLE: "Client",
  POWERBI_FILTER_Client_Id_COLUMN: "RemoteId",
  POWERBI_FILTER_Brand_Name_TABLE: "BranchLocations",
  POWERBI_FILTER_Brand_Name_COLUMN: "Brand",
  POWERBI_FILTER_Locations_TABLE: "BranchLocations",
  POWERBI_FILTER_Locations_COLUMN: "Id",
  POWERBI_FILTER_VisitDate_TABLE: "Dates",
  POWERBI_FILTER_VisitDate_COLUMN: "Date",
  POWERBI_FILTER_Period_Name_TABLE: "ClientPeriods",
  POWERBI_FILTER_Period_Name_COLUMN: "Name",
  POWERBI_FILTER_AreasAndRegions_TABLE: "BranchLocations",
  POWERBI_FILTER_AreasAndRegions_COLUMN: "Area",
  POWERBI_FILTER_Segments_TABLE: "BranchLocations",
  POWERBI_FILTER_Segments_COLUMN: "Segment",
  POWERBI_FILTER_LocationRank_TABLE: "BranchLocations",
  POWERBI_FILTER_LocationRank_COLUMN: "Id",
  POWERBI_FILTER_VisitType_TABLE: "VisitTypes",
  POWERBI_FILTER_VisitType_COLUMN: "TypeCode",
  POWERBI_FILTER_QuestionnaireType_TABLE: "Questions",
  POWERBI_FILTER_QuestionnaireType_COLUMN: "QuestionnaireType",
  POWERBI_FILTER_Questions_TABLE: "Questions",
  POWERBI_FILTER_Questions_COLUMN: "QuestionText",
  POWERBI_FILTER_VISIT_DATE_FORMAT_START: "YYYY-MM-DDT00:00:00",
  POWERBI_FILTER_VISIT_DATE_FORMAT_END: "YYYY-MM-DDT23:59:59",
  POWERBI_FILTER_Question_Subject_TABLE: "Questions",
  POWERBI_FILTER_Question_Subject_COLUMN: "QuestionSubject",
  POWERBI_FILTER_Sections_TABLE: "Questions",
  POWERBI_FILTER_Sections_COLUMN: "Section",
  POWERBI_FILTER_QuestionCategory_TABLE: "Questions",
  POWERBI_FILTER_QuestionCategory_COLUMN: "Category",
  POWERBI_FILTER_Benchmarks_TABLE: "Questions",
  POWERBI_FILTER_Benchmarks_COLUMN: "BenchmarkType",
  POWERBI_FILTER_AnalysisTags_TABLE: "Questions",
  POWERBI_FILTER_AnalysisTags_COLUMN: "AnalysisTag",
  POWERBI_FILTER_NPS_TABLE: "VisitScores",
  POWERBI_FILTER_NPS_COLUMN: "NPSClassification",
  POWERBI_FILTER_VisitScoreRange_TABLE: "VisitScores",
  POWERBI_FILTER_VisitScoreRange_COLUMN: "CalculatedScore",
  POWERBI_FILTER_QuestionScoreRange_TABLE: "QuestionScores",
  POWERBI_FILTER_QuestionScoreRange_COLUMN: "ScoreValue",
  POWERBI_FILTER_DayOfWeek_TABLE: "Dates",
  POWERBI_FILTER_DayOfWeek_COLUMN: "WeekDay",
  POWERBI_FILTER_DayPart_TABLE: "TimeframeNames",
  POWERBI_FILTER_DayPart_COLUMN: "Timeframe",
  POWERBI_FILTER_VisitAnalysisDepartment_TABLE: "AnalysisTags",
  POWERBI_FILTER_VisitAnalysisDepartment_COLUMN: "Department",
  POWERBI_FILTER_VisitAnalysisRoom_TABLE: "AnalysisTags",
  POWERBI_FILTER_VisitAnalysisRoom_COLUMN: "Room",
  POWERBI_FILTER_TaskCentreStatus_TABLE: "ActionCentre",
  POWERBI_FILTER_TaskCentreStatus_COLUMN: "WorkflowStatus",
  POWERBI_FILTER_TaskCentreCategory_TABLE: "ActionCentre",
  POWERBI_FILTER_TaskCentreCategory_COLUMN: "Category",
  POWERBI_FILTER_TaskCentrePriority_TABLE: "ActionCentre",
  POWERBI_FILTER_TaskCentrePriority_COLUMN: "Priority",
  POWERBI_FILTER_VisitSource_TABLE: "Visits",
  POWERBI_FILTER_VisitSource_COLUMN: "VisitSource",
  POWERBI_FILTER_FeedbackCategory_TABLE: "AnalysisTags",
  POWERBI_FILTER_FeedbackCategory_COLUMN: "Feedback Category",
  POWERBI_FILTER_FeedbackSubCategory_TABLE: "AnalysisTags",
  POWERBI_FILTER_FeedbackSubCategory_COLUMN: "Sub Category",
  POWERBI_FILTER_LengthOfService_TABLE: "AnalysisTags",
  POWERBI_FILTER_LengthOfService_COLUMN: "Length of Service",
  POWERBI_FILTER_PeriodHierarchy_Id_TABLE: "ReportingPeriods",
  POWERBI_FILTER_PeriodHierarchy_Id_COLUMN: "PeriodReporting_WeekId",

  // Render Errors
  REPORT_RENDER_Render_Error_Title:
    "Une erreur est survenue lors du rendu de votre rapport.",
  REPORT_RENDER_Render_Error_Header:
    "Notre équipe a été informée.\nVeuillez rafraîchir la page et ressayer.",
  REPORT_RENDER_Render_Error_Message:
    "Si cela ne fonctionne pas, veuillez patienter quelques instants puis ressayez. Si cela bloque toujours, veuillez contacter **support-email**.",
  REPORT_RENDER_Render_Error_AdditionalNotice: "",
  REPORT_RENDER_Refresh_Page: "Rafraîchir la page ",
  REPORT_RENDER_Copy_Email: "Copier l’email Support vers le presse-papiers",
  REPORT_RENDER_Render_Error_Support_Email: "support@hgem.com",

  FORM_FIELD_ERROR_Default_Title: "Erreur mise à jour:",
  FORM_FIELD_ERROR_Close: "Fermer",

  // Task Centre
  TASK_CENTRE_Title: "Centre de tâches",
  TASK_CENTRE_PAGE_Manage_Tasks: "Gérer Tâches",
  TASK_CENTRE_PAGE_Statistics: "Statistiques",
  TASK_CENTRE_WORKFLOW_STATUS_Discussion: "Discussion",
  TASK_CENTRE_WORKFLOW_STATUS_ActionRequired: "Action requise",
  TASK_CENTRE_WORKFLOW_STATUS_TaskRequired: "Action requise",
  TASK_CENTRE_WORKFLOW_STATUS_InProgress: "En cours",
  TASK_CENTRE_WORKFLOW_STATUS_ActionTaken: "Action effectuée",
  TASK_CENTRE_WORKFLOW_STATUS_DoNotAction: "Ne pas Action",
  TASK_CENTRE_WORKFLOW_PRIORITY_Normal: "Normal",
  TASK_CENTRE_WORKFLOW_PRIORITY_High: "Elevé",
  TASK_CENTRE_WORKFLOW_PRIORITY_Low: "Faible",
  TASK_CENTRE_CATEGORY_OPTION_None: "Aucun",
  TASK_CENTRE_CASE_LIST_Error_Message:
    "Une erreur s’est produite. Veuillez rafraîchir la page et ressayer.",
  TASK_CENTRE_CASE_LIST_Refresh_Page: "Rafraîchir la page",
  TASK_CENTRE_CASE_LIST_Load_More_Items: "Charger plus",
  TASK_CENTRE_CASE_LIST_No_More_Items: "Vous n’avez plus tâches.",
  TASK_CENTRE_CASE_LIST_Empty_List_Message:
    "Vous n’avez pas tâches visibles. Veuillez vérifier les filtres appliqués.",
  TASK_CENTRE_TABS_Details: "Détails",
  TASK_CENTRE_TABS_Comments: "Commentaires",
  TASK_CENTRE_TABS_GuestContact: "Contact client",

  TASK_CENTRE_FIELD_Title: "Titre",
  TASK_CENTRE_FIELD_Title_Error_Blank: "Titre ne peut pas être vide.",
  TASK_CENTRE_FIELD_Title_Error_Length:
    "Titre ne peut pas dépasser les 100 caractères.",
  TASK_CENTRE_FIELD_WorkflowStatus: "État",
  TASK_CENTRE_FIELD_Category: "Catégorie",
  TASK_CENTRE_CATEGORY_Empty: "Pas de catégorie donnée",
  TASK_CENTRE_FIELD_Priorities: "Priorité",
  TASK_CENTRE_ASSIGNEE_Assignee: "Assigné à (recevra une alerte e-mail)",
  TASK_CENTRE_ASSIGNEE_Unasigned:
    "Sélectionnez le destinataire (vous recevrez une alerte par e-mail)",
  TASK_CENTRE_ASSIGNEE_Unknown: "Manager inconnu:",
  TASK_CENTRE_ASSIGNEE_Loading: "Recherche coordonnées manager...",
  TASK_CENTRE_FIELD_Due_Date: "Date limite",
  TASK_CENTRE_DUEDATE_No_Due_Date: "Pas de date limite",
  TASK_CENTRE_NOTIFICATION_SUCCESS: "Mise à jour de tâche réussie.",
  TASK_CENTRE_NOTIFICATION_ERROR:
    "Une erreur est survenue lors de la mise à jour de tâche. Veuillez ressayer.",
  TASK_CENTRE_Timestamp_Format: "ddd DD MMM YYYY HH:mm",
  TASK_CENTRE_FIELD_Comments: "Ajouter un commentaire...",
  TASK_CENTRE_COMMENTS_Submit_Comment: "Poster commentaire",
  TASK_CENTRE_FIELD_Comments_Remaining_Chars: "Caractères restants",
  TASK_CENTRE_COMMENTS_Leave_a_Comment: "Ajouter un commentaire:",
  TASK_CENTRE_COMMENTS_Internal: "(Interne)",

  TASK_CENTRE_GENERIC_Error_Title: "Erreur mise à jour tâche",
  TASK_CENTRE_ERROR_CONFIRMATION_Close: "Fermer",
  TASK_CENTRE_COMMENTS_Error_Message:
    "Désolé, une erreur est survenue lors de l’affichage de votre commentaire. Veuillez ressayer.",
  TASK_CENTRE_Comment_Count: "Commentaires: ",
  TASK_CENTRE_Guest_Contact_Available: "État contact: ",
  TASK_CENTRE_Guest_Contact_Confirmation_Found: "Contact confirmé",
  TASK_CENTRE_Guest_Contact_Message_sent: "Message envoyé",
  TASK_CENTRE_Guest_Contact_Message_sent_and_received: "Message envoyé + reçu",
  TASK_CENTRE_Guest_Contact_Message_not_sent: "En attente contact",
  TASK_CENTRE_Internal_Notes: "Notes internes",

  TASK_CENTRE_FIELD_Guest_Contact_Pending: "Chargement coordonnées client...",
  TASK_CENTRE_FIELD_Guest_Contact_Error_Fetching:
    "Une erreur est survenue lors de la récupération des coordonnées.",
  TASK_CENTRE_FIELD_Guest_Contact_Retry_Fetching:
    "Rafraîchir les coordonnées client",
  TASK_CENTRE_FIELD_Guest_Contact_Subject: "Sujet",
  TASK_CENTRE_FIELD_Guest_Contact_Message: "Message",
  TASK_CENTRE_GUEST_CONTACT_Confirm_Contact: "Remarques",
  TASK_CENTRE_GUEST_CONTACT_Submit_Message:
    "Envoyer un message direct au client",
  TASK_CENTRE_GUEST_CONTACT_Error_Title: "Erreur d’envoi du message",
  TASK_CENTRE_GUEST_CONTACT_Error_Message:
    "Une erreur est survenue lors de l’envoi du message au client.",
  TASK_CENTRE_FIELD_Guest_Contact_Name: "Nom:",
  TASK_CENTRE_GUEST_CONTACT_ATTACHMENT_SIZE_CANNOT_EXCEED:
    "La taille totale de tous les fichiers ne peut pas dépasser",

  TASK_CENTRE_GUEST_CONTACT_NAME_FALLBACK: "[INSÉRER NOM CLIENT/TITRE]",
  TASK_CENTRE_GUEST_CONTACT_BRANCH_NAME_FALLBACK: "[INSÉRER NOM SUCCURSALE]",

  TASK_CENTRE_GUEST_DETAILS_Message_Confirmation_Title: "Contact client:",
  TASK_CENTRE_GUEST_DETAILS_Message_Confirmation_Description:
    "Destinataire message : ",
  TASK_CENTRE_GUEST_DETAILS_Message_Confirmation_CancelTask: "Annuler",
  TASK_CENTRE_GUEST_DETAILS_Message_Confirmation_SendTask: "Envoyer message",

  TASK_CENTRE_GUEST_DETAILS_Contact_Confirmation_Title: "Contact client:",
  TASK_CENTRE_GUEST_DETAILS_Contact_Confirmation_Description:
    "Vous pouvez utiliser ceci pour confirmer que le client a été contacté en-dehors de la messagerie directe du Hub, soit par téléphone ou par email.",
  TASK_CENTRE_GUEST_DETAILS_Contact_Confirmation_Notes: "Notes (facultatif)",
  TASK_CENTRE_GUEST_DETAILS_Contact_Confirmation_CancelTask: "Annuler",
  TASK_CENTRE_GUEST_DETAILS_Contact_Confirmation_ConfirmTask:
    "Confirmer que le client a été contacté",
  TASK_CENTRE_GUEST_DETAILS_Contact_Confirmation_Notes_Error:
    "Les notes ne peuvent pas dépasser les 1000 caractères.",

  TASK_CENTRE_Message_Sent_To_Guest: "Message envoyé au client",
  TASK_CENTRE_Message_Received_From_Guest: "Message reçu",
  TASK_CENTRE_Contact_Confirmed: "Contact client confirmé",
  TASK_CENTRE_Contact_Confirmed_No_Comment: "Pas de notes ajoutées",

  TASK_CENTRE_VISIT_DETAILS_PREF_DownloadVisitReport:
    "Télécharger le rapport de visite",
  TASK_CENTRE_VISIT_DETAILS_PREF_ViewReportInInbox:
    "Ouvrir dans un nouvel onglet Boîte Mail",
  TASK_CENTRE_VISIT_DETAILS_Score: "Score: ",
  TASK_CENTRE_VISIT_DETAILS_NullScore: "Score: n/a",

  TASK_CENTRE_EDIT_CASE_DELETE_Title: "Confirmer suppression:",
  TASK_CENTRE_EDIT_CASE_DELETE_Message:
    "Êtes-vous sûr de vouloir supprimer cette tache ?",
  TASK_CENTRE_EDIT_CASE_DELETE_Cancel: "Annuler",
  TASK_CENTRE_EDIT_CASE_DELETE_Delete: "Supprimer",

  TASK_CENTRE_CASE_LIST_FILTER_PlaceholderText:
    "Chercher Titre, Commentaire, ou Message",
  TASK_CENTRE_CASE_LIST_FILTER_ClearSearch: "Effacer recherche",
  TASK_CENTRE_PLACEHOLDER_Guest_contact_subject: "votre récente visite à ",

  TASK_CENTRE_CASE_LIST_FILTER_ContactFilter_Prefix: "État contact: ",
  TASK_CENTRE_CASE_LIST_FILTER_ContactFilter_AllCases: "Toutes taches",
  TASK_CENTRE_CASE_LIST_FILTER_ContactFilter_CasesAwaitingContact:
    "En attente de contact",
  TASK_CENTRE_CASE_LIST_FILTER_ContactFilter_CasesWithCompletedContacts:
    "Contacté",
  TASK_CENTRE_CASE_LIST_FILTER_OrderText: "Trié par communication plus récente",
  TASK_CENTRE_CASE_LIST_FILTER_Showing: "Afficher",
  TASK_CENTRE_CASE_LIST_FILTER_Cases_out_of: "tâche: sur ",

  TASK_CENTRE_CASE_ITEM_Date_Format: "ddd DD MMM YYYY",
  TASK_CENTRE_CASE_ITEM_Created: "Créé: ",
  TASK_CENTRE_CASE_ITEM_Visit: "Visite: ",

  TASK_CENTRE_LIST_Select_Task: "Task sélectionnée",
  TASK_CENTRE_LIST_NoTaskSelected: "n/a",

  TASK_CENTRE_Quick_Task_Create:
    "Choisir un nouveau état tache pour cette visite",
  TASK_CENTRE_Create_Additional_Task: "Créer tâche supplémentaire",
  TASK_CENTRE_Delete_Task: "Supprimer tâche",
  TASK_CENTRE_VIEW_ATTACHMENTS: "afficher les pièces jointes",
  TASK_CENTRE_VIEW_COMMENTS: "voir les commentaires",

  TASK_CENTRE_CARD_no_contact_details: "Aucune coordonnée disponible",
  TASK_CENTRE_CARD_awaiting_first_contact: "En attente du premier contact",
  TASK_CENTRE_CARD_awaiting_reply: "Invité en attente de réponse",
  TASK_CENTRE_CARD_guest_contacted: "L'invité a été contacté",
  TASK_CENTRE_CARD_visit_score: "Note de visite",
  TASK_CENTRE_CARD_last_communication: "la dernière message",
  TASK_CENTRE_CARD_assignee: "cessionnaire actuel",
  TASK_CENTRE_CARD_no_assignee:
    "Personne n'est actuellement affecté à cette tâche",
  TASK_CENTRE_CARD_voucher: "Le bon a été envoyé",
  TASK_CENTRE_CARD_number_of_messages: "Nombre de messages",
  TASK_CENTRE_CREATED_DATE: "Date de création :",
  TASK_CENTRE_CARD_Days_open: "Jours d'ouverture",
  TASK_CENTRE_CARD_Day_open: "Journée ouverte",

  // E-Learning
  ELEARNING_Title: "Modules de formation en ligne Little GEM",
  ELEARNING_TEMP_Message:
    "Nos modules de formation en ligne seront de nouveau disponibles bientôt.",

  // 404 Not Found + Error Page
  NOTFOUND_Title: "Désolé, la page que vous cherchez n’est pas disponible...",
  NOTFOUND_HomePage_Text: "Cliquez ici pour la page d’accueil",
  NOTFOUND_Alternative_links:
    "Ou bien, si le problème persiste, veuillez contacter HGEM Client Support via support@hgem.com ou via notre ",
  NOTFOUND_Alternative_contact_page: "https://www.hgem.com/contact-us",
  NOTFOUND_Alternative_contact_page_token: "Page contact.",

  ERRORPAGE_Title: "Oops!",
  ERRORPAGE_Subtitle: "Nous avons enregistré une erreur et allons l'examiner.",
  ERRORPAGE_BackButtonHint: "S'il vous plaît, essayez de",
  ERRORPAGE_HomePage_Text: "Cliquez ici pour la page d’accueil ",
  ERRORPAGE_Alternative_links:
    "A défaut, contactez-nous via le chat (ci-dessous) ou par email ",
  ERRORPAGE_Alternative_contact_page: "support@hgem.com",
  ERRORPAGE_Alternative_contact_page_token: "support@hgem.com",
  ERRORPAGE_ResetPrefix: "cette session ou utilisez un autre navigateur",
  ERRORPAGE_ResetLinkText: "Réinitialisez votre session Hub",

  AUTH_ERRORPAGE_Title:
    "Désolé, une erreur est survenue lors du processus de connexion.",
  AUTH_ERRORPAGE_Subtitle:
    "Nous en avons pris note et allons examiner le problème.",
  AUTH_ERRORPAGE_Subtitle_MultiTenant:
    "Si vous avez utilisé l’option ‘Connexion avec Microsoft’, veuillez confirmer que votre organisation est configurée pour cette fonctionnalité.",
  AUTH_ERRORPAGE_RetryHint:
    "Ceci peut être un problème temporaire avec le site ou un problème avec votre compte. Veuillez vérifier vos coordonnées et ",
  AUTH_ERRORPAGE_RetryHint_MultiTenant:
    "Veuillez vérifier vos données de connexion et cliquez ici pour ",
  AUTH_ERRORPAGE_ResetLinkTextMultiTenant: "Redémarrer votre session Hub.",

  // Visit Report - Direct Download
  VISIT_REPORT_DOWNLOAD_Title: "Télécharger le rapport de visite",
  VISIT_REPORT_DOWNLOAD_Download_Running:
    "Votre rapport de visite est en cours de téléchargement...",
  VISIT_REPORT_DOWNLOAD_Download_Try_Again_Message:
    "Si le téléchargement n’est pas complété automatiquement, veuillez patienter quelques instants et",
  VISIT_REPORT_DOWNLOAD_Download_Try_Again: "Cliquez ici pour ressayer.",
  VISIT_REPORT_DOWNLOAD_Download_Error:
    "Une erreur est survenue lors de la création de votre rapport de visite.",
  VISIT_REPORT_DOWNLOAD_Parse_URL_Error:
    "Une erreur est survenue. La référence de rapport de visite fournie n’est pas valide.",
  VISIT_REPORT_DOWNLOAD_Download_Complete: "Téléchargement terminé.",
  VISIT_REPORT_DOWNLOAD_View_More_Reports:
    "Affichez tous vos rapports de visite en allant à",
  VISIT_REPORT_DOWNLOAD_Visit_Details: "Détails de visite",

  INBOX_TABS_VisitReport: "Rapport de visite",
  INBOX_TABS_Media: "Image & son",
  INBOX_TABS_Tasks: "Tâches",
  INBOX_TABS_Comments: "Commentaires",
  INBOX_TABS_GuestCommunications: "Communications client",
  INBOX_TABS_Appeals: "Appels",

  VISIT_DETAILS_LIST_Refresh_Page: "Rafraîchir la page",
  VISIT_DETAILS_LIST_Error_Message: "Erreur téléchargement visites",
  VISIT_DETAILS_LIST_Empty_List_Message:
    "Vous n’avez pas de visites affichées. Veuillez vérifier les filtres appliqués.",
  VISIT_DETAILS_LIST_Load_More_Items: "Télécharger d’autres visites",
  VISIT_DETAILS_LIST_No_More_Items: "Vous n’avez pas d’autres visites.",

  VISIT_DETAILS_LIST_ITEM_Score: "Score:",
  VISIT_DETAILS_LIST_ITEM_Type: "Type:",
  VISIT_DETAILS_LIST_ITEM_HasMedia: "Image et son",
  VISIT_DETAILS_LIST_ITEM_HasAppeal: "Appel",
  VISIT_DETAILS_LIST_ITEM_TaskCount_TooltipSuffix:
    "Task(s) liées à cette visite",

  VISIT_DETAILS_LIST_ITEM_GUEST_CONTACT_Review: "État page d’avis",
  VISIT_DETAILS_LIST_ITEM_GUEST_CONTACT_GuestContact: "État contact",
  VISIT_DETAILS_LIST_ITEM_GUEST_CONTACT_Review_Responded: "Répondu",
  VISIT_DETAILS_LIST_ITEM_GUEST_CONTACT_GuestContact_ContactMade: "Contacté",
  VISIT_DETAILS_LIST_ITEM_GUEST_CONTACT_Review_Pending_Response:
    "En attente de réponse",
  VISIT_DETAILS_LIST_ITEM_GUEST_CONTACT_GuestContact_Awaiting_Contact:
    "En attente de contact",

  VISIT_DETAILS_LIST_NoVisitSelected: "n/a",
  VISIT_DETAILS_LIST_Select_Visit: "Visite sélectionnée",
  VISIT_DETAILS_LIST_Date_Format: "ddd DD MMM YYYY",

  VISIT_DETAILS_TASK_SELECTOR_SelectorSuffix: "Modifier tâche sélectionnée",

  VISIT_DETAILS_TASK_CREATOR_New_Task:
    "Ajouter une action supplémentaire pour cette visite",
  VISIT_DETAILS_TASK_CREATOR_HelpText:
    "Qu'aimeriez-vous accomplir avec cette tâche ?",
  VISIT_DETAILS_TASK_CREATOR_HelpText_examples:
    "Par exemple : Mettre en place des contrôles réguliers de la propreté des toilettes",
  VISIT_DETAILS_TASK_CREATOR_Create_New_Task: "Créer nouvelle tâche",
  VISIT_DETAILS_TASK_CREATOR_CreateTask: "Créer",
  VISIT_DETAILS_TASK_CREATOR_Cancel: "Annuler",
  VISIT_DETAILS_TASK_CREATOR_TaskTitleLabel: "Titre tâche",

  VISIT_DETAILS_TASKS_Comments: "Commentaires",
  VISIT_DETAILS_TASKS_GuestContact: "Contact client",
  VISIT_DETAILS_MEDIA_Photo_Enlarge: "Cliquez pour afficher l’image",
  VISIT_DETAILS_MEDIA_Photo_Alt_Text: "Photo visite",
  VISIT_DETAILS_PHOTO_MODAL_Close: "Fermer",
  VISIT_DETAILS_PHOTO_MODAL_Download: "Télécharger image",
  VISIT_DETAILS_PHOTO_MODAL_Filename_Prefix: "Photo visite",
  VISIT_DETAILS_PHOTO_MODAL_Metadata_Date_Prefix: "Date de la prise de photo:",
  VISIT_DETAILS_PHOTO_MODAL_Metadata_Date_Format: "ddd DD MMM YY",
  VISIT_DETAILS_PHOTO_MODAL_Metadata_Location_Prefix: "Lieu:",

  VISIT_DETAILS_AUDIO_TileLabel: "Fichier audio",
  VISIT_DETAILS_AUDIO_MODAL_Title: "Playback audio:",
  VISIT_DETAILS_AUDIO_MODAL_No_Audio_Support:
    "Désolé, le navigateur que vous utilisez ne permet pas le playback audio.",
  VISIT_DETAILS_AUDIO_MODAL_Download: "Télécharger fichier",
  VISIT_DETAILS_AUDIO_MODAL_Close: "Fermer",
  VISIT_DETAILS_AUDIO_MODAL_Filename_Prefix: "Audio visite",
  VISIT_DETAILS_AUDIO_PlayAudio: "Lire audio",

  VISIT_DETAILS_REVIEW_HEADER_Date_Format: "dddd DD MMMM YYYY HH:mm",
  VISIT_DETAILS_REVIEW_HEADER_Area_Prefix: "Secteur: ",
  VISIT_DETAILS_REVIEW_HEADER_Source_Prefix: "Source: ",
  VISIT_DETAILS_REVIEW_HEADER_Period_Prefix: "Période: ",
  VISIT_DETAILS_REVIEW_HEADER_VisitId_Prefix: "Visite: ",
  VISIT_DETAILS_REVIEW_HEADER_EmptyComment_Placeholder: "Pas de commentaire",
  VISIT_DETAILS_REVIEW_HEADER_ScoreSuffix: "/5",
  VISIT_DETAILS_REVIEW_HEADER_Response_Title: "Réponse: ",
  VISIT_DETAILS_REVIEW_HEADER_Response_Date_Format: "dddd DD MMMM YYYY HH:mm",

  VISIT_DETAILS_REVIEW_RESPONSE_Remaining_Chars:
    "Nombre de caractères restants",
  VISIT_DETAILS_REVIEW_RESPONSE_Respond_to_Review: "Répondre à l’avis",
  VISIT_DETAILS_REVIEW_RESPONSE_Post_Response: "Poster réponse",
  VISIT_DETAILS_REVIEW_RESPONSE_Error_Title:
    "Erreur lors de l’ajout de votre réponse",
  VISIT_DETAILS_REVIEW_RESPONSE_Error_Message:
    "Nous n’avons pas pu poster votre réponse à cet avis. Veuillez ressayer.",

  VISIT_DETAILS_REVIEW_RESPONSE_View_Respond: "Afficher ou répondre en",
  VISIT_DETAILS_REVIEW_RESPONSE_Title: "Afficher ou répondre à l’avis:",
  VISIT_DETAILS_REVIEW_RESPONSE_Close: "Fermer",
  VISIT_DETAILS_REVIEW_RESPONSE_ViewReview: "Afficher: ",
  VISIT_DETAILS_REVIEW_RESPONSE_RespondReview: "Répondre: ",
  VISIT_DETAILS_REVIEW_RESPONSE_UnavailableMessage:
    "Il semblerait que nous n’ayons pas l’autorisation de poster des réponses pour cette page d’avis – veuillez demander à votre administrateur de ",
  VISIT_DETAILS_REVIEW_RESPONSE_UnavailableMessage_ContactUs: "Contactez-nous",
  VISIT_DETAILS_REVIEW_RESPONSE_UnavailableMessage_ContactLink:
    "mailto:support@hgem.com",
  VISIT_DETAILS_REVIEW_RESPONSE_View_Only: "Vu dans",
  VISIT_DETAILS_REVIEW_RESPONSE_Use_Template: "Modèles de réponse",
  VISIT_DETAILS_REVIEW_RESPONSE_Insert_Introduction: "Utiliser Introduction",
  VISIT_DETAILS_REVIEW_RESPONSE_Insert_Signature: "Utiliser Signature",
  VISIT_DETAILS_REVIEW_RESPONSE_Reset: "Réinitialiser",
  VISIT_DETAILS_REVIEW_TEMPLATE_DIALOG_Instructions: "Insérer réponse texte:",
  VISIT_DETAILS_REVIEW_TEMPLATE_DIALOG_Select: "Sélectionner",
  VISIT_DETAILS_REVIEW_TEMPLATE_DIALOG_Close: "Fermer",

  VISIT_DETAILS_REPORT_ERROR_Title:
    "Erreur lors de la récupération du rapport de visite",
  VISIT_DETAILS_REPORT_ERROR_Message:
    "Veuillez essayer de resélectionner cette visite ou de sélectionner une autre visite. Si le problème persiste, essayez de rafraîchir la page.",
  VISIT_DETAILS_REPORT_PDF_Download: "Télécharger PDF",

  VISIT_DETAILS_LIST_RESULTS_MATCHING_FILTERS:
    "Résultats correspondant aux filtres",
  TASK_CENTRE_CASE_LIST_RESULTS_MATCHING_FILTERS:
    "Résultats correspondant aux filtres",

  INBOX_PAGE_Title: "Boîte Mail",
  INBOX_Tabname: "Boîte Mail",
  INBOX_Tasks_Tabname: "Tâches",
  INBOX_Images_Tabname: "Images",
  INBOX_Appeals_Tabname: "Appels",

  INBOX_GUEST_CONTACT_No_contact_available:
    "Les coordonnées du client ne sont pas disponibles pour cette visite.",
  INBOX_MEDIA_no_media_available:
    "Pas de fichiers photo/média disponibles pour cette visite.",
  INBOX_MEDIA_Photos: "Photos:",
  INBOX_MEDIA_AudioFiles: "Fichiers audio:",
  INBOX_MEDIA_Receipts: "Reçus:",
  INBOX_CARD_visit_number: "Numéro visite:",
  INBOX_CARD_visit_source: "Source visite:",
  INBOX_CARD_visit_type: "Type visite:",
  INBOX_GUEST_CONTACT_previous_communications: "Communications antérieures de ",

  // Vouchers
  TOGGLE_SELECT_OPTION: "Sélectionnez l'option du bon",
  TOGGLE_SELECT_PRODUCT: "Sélectionnez le type de bon",
  TOGGLE_VOUCHER_TYPE: "Type de bon",

  GIFT_CARD_DIALOG_CANCEL: "Annuler",
  GIFT_CARD_DIALOG_ACCEPT: "Accepter",
  GIFT_CARD_FINAL_DIALOG_TITLE: "Confirmation finale",
  GIFT_FINAL_DIALOG_CANCEL: "Annuler",
  GIFT_CARD_FINAL_DIALOG_CONFIRM_AND_SEND: "Confirmer et envoyer",
  GIFT_CARD_BUTTON_NAME_TOGGLE: "Carte cadeau",
  GIFT_CARD_AMOUNT: "Valeur carte cadeau:",
  GIFT_CARD_MESSAGE_TO_GUEST: "Message au client:",
  GIFT_CARD_REASON_FOR_CARD: "Raison pour l’envoi de la carte cadeau:",
  GIFT_CARD_REASON_FOR_GIFT_CARD_CONFIRM: "Raison",
  GIFT_CARD_TO: "À:",
  GIFT_CARD_VALUE_EMPTY: "Pas de valeur établie",
  GIFT_CARD_ALREADY_SENT: "Vous avez déjà envoyé une carte cadeau à ce client",
  GIFT_CARD_MESSAGE_APPEND_TOGGLE:
    "Le texte 'N.B. Un bon d’achat Toggle vous sera envoyé séparément via no-reply@mytoggle.io' sera ajouté à votre email",
  GIFT_CARD_MESSAGE_APPENDIX:
    "N.B. Un bon d’achat Toggle vous sera envoyé séparément via no-reply@mytoggle.io",
  GIFT_CARD_CUSTOM_VALUE_PLACEHOLDER:
    "Veuillez indiquer une valeur pour la carte cadeau",
  GIFT_CARD_CUSTOM_VALUE_LABEL: "Personnaliser valeur",
  GIFT_CARD_CUSTOM_VALUE_VALIDATION_ERROR_TEXT:
    "La valeur doit être de 1 ou plus et un nombre entier",
  GIFT_CARD_VALUE_UPPER_LIMIT_ERROR: "La valeur ne peut pas dépasser",
  VOUCHER_DETAILS_SENT_HEADER: "Bon d’achat Toggle envoyé",
  VOUCHER_DETAILS_SENT_REASON: "Raison pour le bon d’achat:",
  VOUCHER_DETAILS_SENT_AMOUNT: "Montant du bon d’achat:",

  // E-Learning
  "ELearning-AppearanceTitle": "Fiers de notre apparence",
  "ELearning-AppearanceDescription":
    "<p><span class='highlight'>Aspirations de l’entreprise:</span> Je ne voudrais pas qu’un client remarque un manque de fierté quant à l’apparence de notre enseigne ou du personnel et ce, à n’importe quel moment de leur visite </p><p><span class='highlight'>Considérez</span></p><ul><li>Pourquoi le souci du détail et la fierté vis-à-vis de notre enseigne sont tellement importants (les gens ne remarquent pas lorsque tout est propre mais quand ce n’est pas le cas, alors cela saute aux yeux !) </li><li>Notez les choses sur lesquelles il vous faut garder un œil, soyez conscient du désordre, de l’apparence extérieure, de l’apparence personnelle, des surfaces – des choses que l’on ne remarque pas du premier coup d’œil</li><li>Toilettes toilettes toilettes – faites-y attention, toujours</li><li>Nettoyer au fur et à mesure, tous les postes de travail rangés</li><li>Compréhension des directives de la marque</li><li>Menus propres, bacs de fleurs, panneaux</li><li>Mettez-vous à la place des clients – regardez l’enseigne de l’extérieur, ou asseyez-vous à une table et observez de ce point de vue</li><li>Créez des liens de sorte qu’il soit plus aisé pour les clients de vous faire part d’un problème, et sachez comment gérer les plaintes</li><li>Faites en sorte que les clients puissent facilement trouver ce dont ils ont besoin (couverts, condiments, etc.)</li><li>Regardez-vous dans le miroir et observez vos collègues – cheveux, dents et ongles propres</li></ul>",
  "ELearning-BehavioursTitle":
    "Offrir une expérience client aimable et personnalisée",
  "ELearning-BehavioursDescription":
    "<p><span class='highlight'>Aspirations de l’entreprise:</span> Je voudrais que chaque client ait l’impression que non seulement nous sommes aimables mais également attentifs à leurs besoins, au lieu de simplement suivre un processus</p> <p><span class='highlight'>Considérez</span></p> <ul>  <li>Qu’est-ce qu’un comportement remarquable</li> <li>Chacun est unique</li> <li>Soyez chaleureux et disponible aussi bien pour vos collègues que pour vos clients</li> <li>Soyez disponible et facile d’abord pour tous vos clients, même si vous n’avez pas eu de contact avec eux</li> <li>Soyez attentif aux clients, identifiez leurs besoins et ce qui est acceptable pour cette table</li> <li>Ayez conscience de votre apparence lorsque vous n’êtes pas à la table</li> <li>Levez le regard, ne regardez pas par terre</li> <li>Soyez disponible durant l’entièreté de l’expérience</li> <li>Ne courez pas, restez calme et maîtrisez la situation</li> <li>Langage corporel – contact visuel, sourire, attitude ouverte et détendue, miroitage</li> <li>Comment ceci influence le comportement et les émotions des clients</li> <li>Se comporter comme un hôte</li> <li>Être attentif</li>  </ul>",
  "ELearning-ConversationTitle":
    "Construire des relations par le biais de conversations",
  "ELearning-ConversationDescription":
    "<p><span class='highlight'> Aspirations de l’entreprise:</span> Je voudrais que chaque client ait l’impression que nos conversations étaient agréables et pertinentes et non préparées à l’avance</p> <p><span class='highlight'>Considérez</span></p> <ul>  <li>Observez, puis impliquez-vous de manière créative</li> <li>Reconnaître une bonne relation lorsqu’elle se présente</li> <li>Identifier lorsque quelqu’un souhaite être laissé en paix</li> <li>L’effet d’une conversation agréable sur le client (se sent détendu/ unique/ bienvenu)</li> <li>Ne donnez pas l’impression d’utiliser des formules préétablies</li> <li>Traitez chaque client comme un individu</li> <li>N’ayez pas peur de traiter les clients différemment en fonction de qui ils sont</li> <li>L’effet relaxant sur vous et sur le client</li> <li>Mini relation</li> <li>L’impact potentiel sur ce que le client dépense (et le pourboire)</li> <li> Si vous n'êtes pas à l'aise avec la langue locale, utilisez le jeu de rôle pour surmonter les obstacles et améliorer votre confiance en vous </li>  </ul>",
  "ELearning-HomeTitle": "",
  "ELearning-HomeDescription":
    "<p>Guest Experience Management (GEM) est au cœur de toute activité hôtelière voulant stimuler les ventes. Les autres pages de ce site vous montrent comment vous progressez.  Mais que pouvez-vous faire pour améliorer vos résultats ? Cette page est conçue pour vous aider, avec accès à une série de modules de formation en ligne ‘Little GEM’ focalisés sur les points clés de l’expérience client. Considérez-les plutôt comme une inspiration.</p>  <p>Tout commence par la promesse de votre marque – l’expérience que vous souhaitez pour vos clients, et la manière dont celle-ci vous démarque de vos concurrents. Ceci peut être défini en utilisant les segments de la GEM Wheel (voir image) qui sont courants dans la plupart des activités hôtelières et sont souvent utilisés comme cadre de référence dans l’évaluation des opérations et la formation des équipes. Il se peut que votre vocabulaire soit légèrement différent, mais les principes sont les mêmes.</p>  <p>Chaque segment extérieur de la roue renvoie à un module de formation en ligne correspondant. Chaque module peut être complété en 25 minutes environ et peut donc s’avérer utile lors de réunions d’équipe, permettant aux membres d’une équipe de discuter et de donner leur opinion sur le sujet. Les modules sont interactifs, avec une vidéo d’aperçu et des questions situationnelles afin de tester et consolider les connaissances.</p>",
  "ELearning-KnowledgeTitle": "Passionnés par ce que nous proposons",
  "ELearning-KnowledgeDescription":
    "<p><span class='highlight'> Aspirations de l’entreprise:</span> Je voudrais que les clients aient l’impression que notre équipe est enthousiaste et en mesure de les conseiller au sujet du menu et de plats spécifiques</p> <p><span class='highlight'>Considérez</span></p> <ul>  <li>L’impact que vous pouvez avoir sur l’expérience d’une personne en l’aidant à faire son choix – et la manière dont c’est ressenti</li> <li>Quelques astuces pour partager cette passion avec les clients (descriptions de plats, savoir comment les plats sont préparés et la provenance des ingrédients etc.)</li> <li>Montrez que vous êtes intéressé par le travail du cuisinier, soyez curieux et demandez comment l’on pourrait préparer cela chez soi</li> <li>Prenez-en note pour la prochaine fois où vous n’avez pas réponse à une question d’un client</li> <li>Quel est votre plat préféré (ou, si vous n’avez pas encore goûté les plats, qu’est-ce que vous commanderiez) – répétez si nécessaire</li> <li>Les meilleurs façons de se familiariser avec ce que votre marque propose (apprentissage menu, goûter & essayer, aide-mémoires, demander au cuisinier)</li>  </ul>",
  "ELearning-OrganisationTitle": "Fournir un service aisé et naturel",
  "ELearning-OrganisationDescription":
    "<p><span class='highlight'> Aspirations de l’entreprise:</span> Je voudrais que notre équipe travaille ensemble afin de satisfaire les besoins de chaque client et de manière tellement efficace que le service semble aisé et naturel</p> <p><span class='highlight'>Considérez</span></p> <ul>  <li>“Je vais manger là où je sais que ce sera facile”</li> <li>Ôter de l’expérience tous les points d’achoppement de sorte que le client n’a à s’inquiéter de rien</li> <li>Répéter les étapes du service afin d’être à l’aise dans une structure et de pouvoir gérer à tout moment (les connaître par cœur)</li> <li>Ayez conscience de votre impact sur les autres membres de l’équipe et vice versa </li> <li>Soyez au courant de ce que les autres font</li> <li>Laissez les choses telles que vous aimeriez les trouver – respectez vos collègues</li> <li>Identifiez les obstacles ou engorgements (stock, couverts, etc.)</li> <li>Savoir quand prendre du recul par rapport au processus</li> <li>Identifier quand il s’agit d’ouvrir une ardoise</li> <li>Ce que le client perçoit comme remarquable</li> <li>Avoir une longueur d’avance et anticiper les choses avant qu’elles n’arrivent</li> <li>Préparation pour l’ouverture/ passation/ fermeture et réunions pré-service</li>  </ul>",
  "ELearning-PaceTitle": "Bien gérer le timing",
  "ELearning-PaceDescription":
    "<p><span class='highlight'> Aspirations de l’entreprise:</span> Je ne voudrais pas qu’un client se sente soit pressé, soit laissé-pour-compte, à n’importe quelle étape de l’expérience</p> <p><span class='highlight'>Considérez</span></p> <ul>  <li>Connaître les attentes liées aux différentes étapes de l’expérience de votre marque</li> <li>Si une table doit être libérée à une heure précise, offrez un service qui tient compte de ceci</li> <li>Les familles peuvent apprécier que l’on serve les enfants plus rapidement</li> <li>Identifier quand il s’agit de changer la priorité des commandes pour les cuisines</li> <li>Communiquez avec les cuisines</li> <li>S’il n’y a pas de rush, offrez-leur quelque chose pendant qu’ils attendent</li> <li>Il est difficile de dépasser les attentes, mais facile de décevoir</li> <li>Évaluer les besoins de différents clients et réagir en conséquence</li> <li>C’est ok de demander si c’est un repas d’affaires ou pré-théâtre</li> <li>Sachez quand apporter l’addition</li> <li>Restez positif et concentré sur les clients jusqu’à la fin – ne commencez pas à préparer les tables pour le lendemain pendant le dîner</li> <li>Ce qui est perçu comme remarquable</li>  </ul>",
  "ELearning-PaymentTitle": "Paiement efficace & Au revoir aimable ",
  "ELearning-PaymentDescription":
    "<p><span class='highlight'> Aspirations de l’entreprise:</span> Je voudrais que chaque client ait l’impression qu’il était facile de payer l’addition, que c’était efficace et apprécié, et que nous serions heureux de les revoir</p> <p><span class='highlight'>Considérez</span></p> <ul>  <li>Ayez conscience du lien entre cette étape de l’expérience et le fait que cela peut laisser une mauvaise impression si cela n’a pas été fait correctement</li> <li>Comment identifier lorsque les clients sont prêts à régler l’addition – s’ils vous cherchent du regard, c’est signe que vous avez échoué</li> <li> Il est difficile de dépasser les attentes, mais facile de décevoir </li> <li>Ce que vous pouvez faire afin de rendre cette étape plus facile et efficace (ex. connaître le processus de base, mais aussi les variantes telles que payement par appli)</li> <li>Faciliter les paiements séparés</li> <li>D’abord vérifier l’addition</li> <li>Demander aux clients de quelle manière ils souhaitent payer avant de produire l’addition</li> <li>Vérifier s’il y a des chèques cadeau</li> <li>Être clair par rapport à l’inclusion, ou non, du service</li> <li>Au revoir – pas seulement au départ des clients mais également au moment du paiement, de la part de la personne qui les a servis</li>  </ul>",
  "ELearning-PromotionTitle": "Comment attirer les clients",
  "ELearning-PromotionDescription":
    "<p><span class='highlight'> Aspirations de l’entreprise:</span> Je voudrais que les clients potentiels se sentent encouragés à nous rendre visite et ce, grâce à l’aspect extérieur de l’établissement, des promotions ou encore, la présence numérique de la marque </p> <p><span class='highlight'>Considérez</span></p> <ul>  <li>Ce qui peut influencer l’aspect extérieur (vitres, entrées, panneaux, pots de fleurs, etc.)</li> <li>Être créatif et attirer l’attention</li> <li>Maintenir votre présence sociale (si cela fait partie de votre rôle), exprimer la personnalité de la marque (pas la vôtre) en ligne, et montrer que vous vous sentez concerné lorsqu’il y a un problème</li> <li>Savoir quelle est la marche à suivre lorsqu’il y a des plaintes exprimées sur les réseaux sociaux</li> <li>Observez ce que font vos concurrents</li> <li>Soyez intéressé par vos propres réseaux sociaux</li> <li>Réfléchissez à ce qui accroche les gens</li> <li>Annoncez les événements à venir</li> <li>Relation avec l’équipe de support du siège social</li>  </ul>",
  "ELearning-QualityTitle": "Garantir des produits de qualité irréprochable",
  "ELearning-QualityDescription":
    "<p><span class='highlight'> Aspirations de l’entreprise:</span> Je voudrais garantir la satisfaction client, faisant en sorte que chaque plat servi correspond aux critères de qualité, température et présentation requis</p> <p><span class='highlight'>Considérez</span></p> <ul>  <li>L’importance des critères en ce qui concerne la cohérence et la gestion des attentes</li> <li>Prenez vos responsabilités</li> <li>Tenez-vous aux critères et assurez-vous que, en tant qu’équipe, nous avons tenu nos promesses</li> <li>Assurez-vous que chaque client reçoit ce qu’il est en droit d’attendre</li> <li>Occupez-vous de / Faites attention à votre équipe et vos </li> <li>Cernez les limites de la responsabilité (ex. si vous pouvez, ou non, proposer une alternative si un client n’aime pas quelque chose</li> <li>Comment développer votre connaissance/expérience des critères et à quoi faut-il faire attention</li> <li>Que faire si vous remarquez que quelque chose ne va pas avant de servir ? (renvoyer en cuisine, en parler au cuisiniers, gérer la communication client)</li> <li>Comment vérifier la satisfaction à table de manière sincère et non mécanique et les choses que vous pourriez apprendre</li> <li>Montrez que vous vous sentez concerné lorsqu’un client n’est pas satisfait</li> <li>Créativité dans la gestion des plaintes</li>  </ul>",
  "ELearning-SellingTitle": "Les clients satisfaits dépensent plus",
  "ELearning-SellingDescription":
    "<p><span class='highlight'> Aspirations de l’entreprise:</span>Je voudrais que chaque client dépense plus, de son propre gré, et qu’il ait l’impression que cela contribue à l’amélioration de son expérience</p> <p><span class='highlight'>Considérez</span></p> <ul>  <li>Ce qu’est la vente incitative et quels sont les autres termes utilisés</li> <li>Quel est l’intérêt pour l’entreprise (revenu)/ le client (expérience améliorée)/ et pour vous (pourboires & fierté d’un travail bien fait)</li> <li>Exemples types de vente incitative : Snacks, pain/ olives, entrées, apéritif, accompagnements, une bouteille de vin si deux verres sont commandés, une option de qualité supérieure/ un plat plus cher, desserts, boissons supplémentaires, cafés, eau</li> <li>Boissons – servez le 1e verre rapidement, puis trouvez l’opportunité d’en proposer un 2e (la plupart des gens ont 1 verre et demi)</li> <li>Agissez de suite lorsque vous remarquez des verres vides</li> <li>La meilleure façon d’inciter à commander plus (recommandations, accorder plats & boissons, connaître le menu par cœur, etc.)</li> <li>Qu’est-ce qui peut arriver de pire ? – Le client refuse (ne vous tracassez pas pour leur budget, chacun a la possibilité de refuser et la plupart des gens ne seront pas offensés) </li> <li>Soyez correct et personnel</li>  </ul>",
  "ELearning-WelcomeTitle":
    "Faire en sorte que chaque client se sente le bienvenu",
  "ELearning-WelcomeDescription":
    "<p><span class='highlight'> Aspirations de l’entreprise:</span> Je voudrais que chaque client ait l’impression que l’on va bien occuper de lui et ce, grâce à la manière dont il a été accueilli</p> <p><span class='highlight'>Considérez</span></p> <ul> <li>À quoi ressemble un excellent accueil, quel que soit l’endroit (pas seulement à l’entrée, mais également l’image que vous donnez via votre page Facebook, ou la manière dont vous répondez au téléphone)</li> <li>Choisir vos comportements pour la journée</li> <li>Être prêt pour l’accueil – noms, chaises hautes, etc.</li> <li>Personnalisez l’accueil pour le client</li> <li>Paraître prêt, même si vous ne l’êtes pas </li> <li>Prendre les devants et faire en sorte que les clients se sentent encadrés et détendus</li> <li>Garder un œil sur l’entrée et les arrivées sans réservation (en particulier s’il n’y a pas d’hôte)</li> <li>La manière dont un excellent accueil prépare le terrain pour une excellent expérience</li> <li>Astuces pour que l’accueil soit au top à chaque fois, même lorsque vous êtes fatigué / occupé ou lorsque les clients sont désagréables</li> </ul>",
  "ELearning-WordOfMouthTitle": "Encourager les recommandations",
  "ELearning-WordOfMouthDescription":
    "<p><span class='highlight'> Aspirations de l’entreprise:</span> Je voudrais donner à chaque client une ‘interaction sociale’ positive lors de leur expérience de sorte qu’ils nous recommandent lorsqu’ils parlent à leurs amis</p> <p><span class='highlight'>Considérez</span></p> <ul>  <li>Les gens partagent des histoires, pas de l’information – “créer des histoires”</li> <li>Faites des heureux – réfléchissez à la manière dont vous pouvez faire cela</li> <li>Il n’y a pas beaucoup de secteurs où vous avez l’opportunité de faire des heureux chaque jour </li> <li>Chacun d’entre nous peut améliorer les choses pour nos clients</li> <li>Intéressez-vous vraiment aux clients</li> <li>Les gens se rendent compte lorsque vous êtes allé au-delà du devoir</li> <li>Les gens recommandent lorsqu’il y a eu quelque chose d’exceptionnel et nous devons donc activement créer des moments mémorables dans l’expérience client</li> <li>Rappelez-vous vos propres émotions lors d’une expérience récente et la manière dont celles-ci ont contribué aux histoires que vous avez racontées</li> <li>L’élément de surprise</li>  </ul>",
  "gem-wheel-people":
    "<h2><i class='fa fa-users' aria-hidden='true'></i>People</h2> <p>Vos collaborateurs sont les ambassadeurs de votre marque. Par conséquent, s’assurer qu’ils savent ce que l’on attend d’eux est crucial afin de fournir l’expérience que vous souhaitez. Cela commence par l’accueil que les gens reçoivent en arrivant et continue avec la manière dont l’équipe se comporte et interagit avec les clients pendant la visite. Trouvez comment faire en sorte que les client parlent du service que vous fournissez.</p>",
  "gem-wheel-process":
    "<h2><i class='fa fa-cog' aria-hidden='true'></i>Processus</h2> <p>Une bonne expérience devrait être ressentie par le client comme étant naturelle et ponctuelle, mais efficace et rentable pour votre entreprise.  Ce n’est pas toujours facile d’équilibrer les deux, surtout lorsque les demandes sont imprévisibles. Réfléchissez à la manière dont vous remarquez si le timing laisse à désirer et ce que vous pouvez faire afin d’éviter que cela se reproduise.</p>",
  "gem-wheel-product":
    "<h2><i class='fa fa-coffee' aria-hidden='true'></i>Produit</h2> <p>Le produit, en général de la nourriture, est l’aspect le plus concret de l’expérience et est souvent mentionné sur les plateformes de feedback. Les équipes doivent être en mesure d’identifier un produit et savoir à quoi celui-ci devrait ressembler de manière à pouvoir répondre aux questions des clients. C’est non seulement l’occasion idéale pour une conversation, mais aussi pour une éventuelle vente additionnelle – l’important est la manière de le faire.</p>",
  "gem-wheel-place":
    "<h2><i class='fa fa-home' aria-hidden='true'></i>Place</h2> <p>Les clients se feront une idée avant d’arriver et ce, par le biais de votre présence en ligne, les promotions et l’aspect extérieur. Ils remarqueront les endroits qui ne sont pas propres, en particulier lorsqu’il s’agit des toilettes. Puis, ils laisseront une impression générale qui sera partagée en ligne, qu’elle soit positive ou négative.</p>",
  ELEARNING_VIEWER_Back_to_Selection: "Retour à sélection modules",
  ELEARNING_VIEWER_Launch_Module: "Lancer module",
  ELEARNING_VIEWER_Error_Message:
    "Une erreur est survenue, veuillez rafraîchir la page et ressayer.",

  // Notification Centre
  NOTIFICATION_CENTRE_ICON_Title: "Notifications",
  NOTIFICATION_CENTRE_DIALOG_Notifications: "Mes notifications:",
  NOTIFICATION_CENTRE_DIALOG_Empty_Notifications:
    "Vous n’avez pas de nouvelles notifications pour cette session",
  NOTIFICATION_CENTRE_DIALOG_Close: "Fermer",
  NOTIFICATION_CENTRE_ICON_New_Popup_Tooltip: "Nouvelle notification",
  NOTIFICATION_CENTRE_Filter_Refresh_Title: "Durée de la session Hub",
  NOTIFICATION_CENTRE_Filter_Refresh_Message:
    "Les paramètres des filtres et autres données peuvent prendre du retard par rapport au contenu de visite le plus récent pendant une session Hub prolongée.",
  NOTIFICATION_CENTRE_Filter_Refresh_Instructions:
    "Rafraîchissez votre session afin de vous assurer d’avoir les données les plus récentes. Vous pouvez reporter ceci si vous êtes en plein milieu d’une tâche.",
  NOTIFICATION_CENTRE_Filter_Refresh_Refresh_Settings: "Rafraîchir paramètres",
  NOTIFICATION_CENTRE_Filter_Refresh_Remind_Later: "Me rappeler plus tard",

  // Appeals

  APPEALS_STATUS_HEADER: "État: ",
  APPEALS_QUESTIONS_HEADER: "Quelle(s) questions(s) souhaitez-vous contester:",
  APPEALS_QUESTIONS_WHOLE_REPORT_SELECTED: "Rapport entier sélectionné",
  APPEALS_QUESTIONS_BONUS_APPEAL_ONLY: "Contester bonus uniquement",
  APPEALS_APPROVER_HEADER: "Approbateur: ",
  APPEALS_CATEGORY_HEADER: "Catégorie: ",
  APPEALS_REVIEW_DECISION_HEADER: "Revoir décision: ",
  APPEALS_COMMENTS_HEADER: "Que souhaitez-vous dire?",
  APPEALS_REVIEW_DECISION: "Revoir décision: ",
  APPEALS_REVIEWING: "Revoir",
  APPEALS_CANCEL: "Refuser",
  APPEALS_EDIT: "Modifier",
  APPEALS_GRANT: " Compléter",
  APPEALS_REJECT: "Refuser",
  APPEALS_CATEGORIES_SELECT_LABEL: "Catégories: ",
  APPEALS_SEND_NOTIFICATION_CHECKBOX_LABEL: "Notifier",
  APPEALS_WHOLE_REPORT_LABEL: "Rapport entier – pas de question spécifique",
  APPEALS_STATUS_NEW: "Nouveau",
  APPEALS_STATUS_RAISED: "Appel fait",
  APPEALS_STATUS_APPROVED_FOR_REVIEW: "Approuvé pour évaluation",
  APPEALS_STATUS_CANCELLED: "Annulé",
  APPEALS_STATUS_UNDER_REVIEW: "En cours d’évaluation",
  APPEALS_STATUS_REJECTED: "Refusé",
  APPEALS_STATUS_GRANTED: "Revu",
  APPEALS_VALIDATION_QUESTIONS: "Vous devez sélectionner au moins une question",
  APPEALS_VALIDATION_SELECT: "Veuillez sélectionner un(e)",
  APPEALS_VALIDATION_APPROVER: "Vous devez sélectionner un approbateur",
  APPEALS_WORKFLOW_STATUS_AppealRaised: "Appel fait",
  APPEALS_WORKFLOW_STATUS_AppealApprovedForReview: "Approuvé pour évaluation",
  APPEALS_WORKFLOW_STATUS_AppealCancelled: "Refusé",
  APPEALS_WORKFLOW_STATUS_AppealUnderReview: "En cours d’évaluation",
  APPEALS_WORKFLOW_STATUS_AppealRejected: "Refusé",
  APPEALS_WORKFLOW_STATUS_AppealGranted: "Revu",
  APPEALS_EDIT_BUTTON_TEXT: "Modifier",
  APPEALS_Edit_SAVE_BUTTON_TEXT: "Sauvegarder",
  APPEALS_Edit_CANCEL_BUTTON_TEXT: "Annuler",
  APPEALS_COMPLETE_REVIEW_BUTTON_TEXT: "Terminer",
  APPEALS_APPROVE_BUTTON_TEXT: "Approve",
  APPEALS_REJECT_BUTTON_TEXT: "Rejeter",
  ATTACHMENT_DOWNLOAD: "Télécharger",
  ATTACHMENT_DELETE: "Supprimer",
  ATTACHMENT_INVALID_EXTENSION: "fichiers ne peuvent pas être téléchargés.",
  ATTACHMENT_INVALID_MAX_SIZE_PART_A: "Fichiers plus lourds que",
  ATTACHMENT_INVALID_MAX_SIZE_PART_B: "MB ne peuvent pas être téléchargés.",
  ATTACHMENT_CONFIRM_DELETE:
    "Êtes-vous sûr de vouloir supprimer cette pièce jointe",
  ATTACHMENT_SELECT_FILE: "Attacher",
  ATTACHMENT_UPLOAD: "Télécharger",
  ATTACHMENT_UPLOAD_ATTACHMENT: "Télécharger pièce jointe",
  ATTACHMENT_DELETE_ATTACHMENT: "Supprimer pièce jointe ",
  ATTACHMENT_CANCEL: "Annuler",
  ATTACHMENTS: "Pièces jointes",
  ATTACHMENT: "Pièce jointe",
  ATTACHMENTS_PENDING: "Fichiers joints",

  ATTACHMENT_CONFIRM_UPLOAD:
    "Êtes-vous sûr de vouloir télécharger cette pièce jointe",
  ATTACHMENT_UPLOADED_BY: "Téléchargé par",

  // Media tab

  MEDIA_TAB_NO_MEDIA_MESSAGE: "Il n’y a pas de media à afficher",
  MEDIA_TAB_END_OF_PHOTOS: "Plus de media à afficher",

  // Conversations

  CONVERSATIONS_LOAD_MORE: "Télécharger plus de conversations",
  CONVERSATIONS_NO_MORE: "Il n’y a pas plus de conversations.",
  CONVERSATIONS_NONE: "Il n’y a pas de conversations à afficher",
  CONVERSATION_MESSAGES_LOADING_MORE: "Téléchargement de plus de messages...",
  CONVERSATION_MESSAGES_NO_MORE: "Il n’y a pas plus de messages.",
  CONVERSATION_MESSAGE_MEDIA_INVALID_FILETYPE:
    "fichiers ne peuvent pas être téléchargés",
  CONVERSATION_MESSAGE_MEDIA_INVALID_FILESIZE_A: "fichiers plus lourds que",
  CONVERSATION_MESSAGE_MEDIA_INVALID_FILESIZE_B:
    "MB ne peuvent pas être téléchargés",
  CONVERSATION_MESSAGE_MEDIA_SELECT_FILE: "Sélectionner fichier",
  CONVERSATION_MESSAGE_MEDIA_LOADING_VIDEO: "Téléchargement de la vidéo...",
  CONVERSATION_MESSAGE_MEDIA_LOADING_IMAGE: "Téléchargement de l’image...",
  MEDIA_MESSAGE_MEDIA_LOADING_AUDIO: "Téléchargement de l’audio...",
  MEDIA_MESSAGE_MEDIA_FILE: "Fichier:",
  CONVERSATIONS_SELECT: "Sélectionner une conversation",
  CONVERSATIONS_SENT_PRIVATELY_TO: "Envoyer en message privé à",
  CONVERSATIONS_SENT_PRIVATELY_VIA: "via",
  CONVERSATION_MESSAGE_ADD_MESSAGE_TEXT: "Ajouter message texte...",
  CONVERSATION_MESSAGE_SEND: "Envoyer",
  CONVERSATION_MESSAGE_CANNOT_SEND:
    "Impossible d’envoyer le message car délai passé",
  CONVERSATION_MESSAGE_SENDING: "envoi...",
  CONVERSATION_MESSAGE_YESTERDAY: "hier",
  CONVERSATION_SOURCE_SMS: "SMS",
  CONVERSATION_SOURCE_WHATSAPP: "WhatsApp",
  CONVERSATION_SOURCE_MESSENGER: "Facebook Messenger",
  CONVERSATION_SOURCE_EMAIL: "Email",
  CONVERSATION_SOURCE_GOOGLE_BUSINESS_MESSENGER: "Google Business Messenger",
  CONVERSATION_SELECTED: "Sélectionner conversation",
  CONVERSATION_MESSAGE_TIME_FORMAT: "HH:mm",
  CONVERSATION_MESSAGE_DATE_FORMAT: "ddd DD MMM YY",
  CONVERSATION_MESSAGE_DATE_TIME_FORMAT_LONG: "HH:mm ddd DD MMM YYYY",
  MEDIA_MESSAGE_IMAGE_THUMBNAIL_Alt_Text: "Image message",
  MEDIA_MESSAGE_IMAGE_THUMBNAIL_Enlarge: "Cliquez pour afficher l’image",
  MEDIA_MESSAGE_IMAGE_MODAL_Close: "Fermer",
  MEDIA_MESSAGE_IMAGE_MODAL_Download: "Télécharger l’image",
  MEDIA_MESSAGE_IMAGE_Alt_Text: "Image message",
  MEDIA_MESSAGE_VIDEO_THUMBNAIL_Alt_Text: "Message Video",
  MEDIA_MESSAGE_VIDEO_THUMBNAIL_Enlarge: "Cliquez pour afficher la vidéo ",
  MEDIA_MESSAGE_VIDEO_MODAL_Close: "Fermer",
  MEDIA_MESSAGE_VIDEO_MODAL_Download: "Télécharger la vidéo",
  MEDIA_MESSAGE_VIDEO_No_Video_Support:
    "Désolé, votre navigateur ne permet pas le playback audio.",
  INBOX_TABS_Conversation: "Conversation",
  VISIT_TYPE_OPTION_CV: "Conversation",
  "Report: Dashboard": "Tableau de bord",
  "Report: AnswerTrends": " Tendances des réponses",
  "Report: AuditScores": "Scores audit",
  "Report: SurveyScores": "Scores sondage",
  "Report: Reviews": "Avis",
  "Report: SurveyDemographics": "Démographique du sondage",
  "Report: DishHeatMaps": "Cartes thermiques plats",
  "Report: SubjectOpinions": "Opinions sujet",
  "Report: SentimentHeatMaps": "Opinion (net)",
  "Report: GuestComments": "Commentaires client",
  "Report: DishComments": "Commentaires plats",
  "Report: AnswerAnalysis": "Analyse réponses",
  "Report: QuestionnaireSections": "Sections questionnaire",
  "Report: QuestionCategories": "Catégories de questions",
  "Report: NetSentiment": "Opinion (net)",
  "Report: TeamFeedback": "Feedback équipe",
  "Report: TeamFeedbackHeatMaps": "Cartes thermiques feedback équipe",
  "Report: BenchmarkHeatMap": "Carte thermique références",
  "Report: GuestDetails": "Coordonnées client",
  "Report: VisitStatistics": "Statistiques visite",
  "Report: VisitFulfilment": "Réalisation visites",
};

export default strings;
